import React from 'react';

import { ActivityTypes } from './activityTypes';

import '../../../../assets/styles/mainPage/card.scss';

const Card: React.FC<ActivityTypes> = ({ title, text, img }) => {
  return (
    <>
      <div className="card">
        <h2 className="activity_title">{title}</h2>
        <div className="card_wrapper">
          <p className="text">{text}</p>
          <div className="img_container">
            <img className="image main_slider_img" src={img} alt={title} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
