import { Footer } from '../../footer/Footer';
import { Header } from '../../header/Header';
import { PrombezSlider } from '../../prombezPage/content/industrialSafety/PrombezSlider';
import { Innovations } from '../../prombezPage/content/innovations/Innovations';
import { medicineInnovationData } from '../../prombezPage/content/innovations/innovationData';
import AdvantagesList from '../../prombezPage/content/ourAdvantages/AdvantagesList';
import Partners from '../../prombezPage/content/partners/Partners';
import { MainMedicalEquipment } from './MainMedicalEquipment';
import { MainMedicinePlasmoran } from './MainMedicinePlasmoran';
import {
  PartnersMedicineData,
  dataSliderMainMedicine,
  medicineAdvdntagesData,
} from './MedicalItemsData';

export function MainMedicinePage() {
  return (
    <>
      <Header />
      <Innovations data={medicineInnovationData} />
      <MainMedicinePlasmoran />
      <MainMedicalEquipment />
      <AdvantagesList data={medicineAdvdntagesData} />
      <PrombezSlider data={dataSliderMainMedicine} />
      <Partners data={PartnersMedicineData} />
      <Footer />
    </>
  );
}
