import { PrombezCardTypes } from '../../prombezTypes';

import '../../../../assets/styles/prombez/prombez.scss';

export const PrombezCard: React.FC<PrombezCardTypes> = ({
  title,
  text,
  img,
}) => {
  const currentPath = location.pathname;
  let prombezTitleMed = '';
  let prombezTextMed = '';
  let prombezCardMed = '';
  let prombezContentBgc = '';
  if (currentPath.includes('/medicine')) {
    prombezTitleMed = 'prombez_title_blue';
    prombezTextMed = 'prombez_text_blue';
    prombezCardMed = 'prombez_card_bg_med';
    prombezContentBgc = 'prombez_content_med';
  }
  return (
    <>
      <div className={`prombez_card ${prombezCardMed}`}>
        <img src={img} alt="img" className="prombez_img" />
        <div className={`prombez_content ${prombezContentBgc}`}>
          <h2 className={`prombez_title ${prombezTitleMed}`}>{title}</h2>
          <article className={`prombez_text ${prombezTextMed}`}>{text}</article>
        </div>
      </div>
    </>
  );
};
