import { ImgsTypes } from '../types/medicineTypes';

interface ImgType {
  data: ImgsTypes;
}

export function GetImgs(data: ImgType) {
  return (
    <div className="imgs_container">
      {data.data.map((img, index) => (
        <img key={index} src={img} alt="img" className="medicine_item_img" />
      ))}
    </div>
  );
}
