import { useEffect } from 'react';
import { textColorChanger } from '../../utils/textColorChanger';

export const AlcoWork = () => {
  useEffect(() => {
    const titleElement = document.querySelector(
      '.work_title'
    ) as HTMLTitleElement;
    textColorChanger(titleElement);
  }, []);
  let alcoControlImg = 'assets/alco_frame/Frame2271.png';
  if (window.innerWidth <= 769) {
    alcoControlImg = 'assets/alco_frame/Untitled.png';
  }
  return (
    <section className="block_container alco_work">
      <h2 className="block_title alco_control_title work_title padding_bottom_0">
        КАК РАБОТАЕТ АЛКОРАМКА
      </h2>
      <img src={alcoControlImg} alt="img" className="alco_work_img" />
      <div className="alco_work_text">
        <p className="block_text">
          Анализатор &quot;Алкорамка&quot; легко интегрируется в систему
          контроля удаленным доступом (СКУД) предприятия. Степень интеграции
          определяется техническими возможностями, например:
        </p>
        <ul className="alco_work_list">
          <li className="block_text ">
            Интеграция со считывателем карты турникета. Появляется возможность
            сопоставить номер измерения анализатора &quot;Алкорамка&quot; с ID
            картой сотрудника. Данные хранятся и отображаются на компьютере в
            формате: номер устройства, дата и время измерения, номер измерения,
            номер турникета, ID карты сотрудника, качественный результат
            (прошёл, не прошёл, некорректный выдох).
          </li>
          <li className="block_text">
            Интеграция со СКУД. Результаты измерения устройства передаются в
            СКУД. В базе данных СКУД в таблице авторизации сотрудников
            добавляются графы: качественный результат (прошёл, не прошёл, не
            корректный выдох).
          </li>
        </ul>
        <p className="block_text">
          Результаты измерений хранятся в устройстве анализатор
          &quot;Алкорамка&quot; в формате, определяемом степенью интеграции, и в
          базе данных СКУД.
        </p>
      </div>
    </section>
  );
};
