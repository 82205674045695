// import '../../../assets/styles/medicine/plasmoran/plasmoran.scss';

// const PdfViewer: React.FC = () => {
//   const toolbar = document.getElementById('toolbar');
//   if (toolbar) {
//     toolbar.classList.add('toolbar');
//   }
//   const openPdfInNewTab = () => {
//     const pdfUrl =
//       'assets/Отчет_о_научно_исследовательской_работе_ВМА_им_Кирова.pdf';
//     window.open(pdfUrl, '_blank');
//   };

//   return (
//     <button
//       className={`button header_btn blue_btn_hover blue_btn plasmoran_btn`}
//       onClick={openPdfInNewTab}
//     >
//       Узнать больше
//     </button>
//   );
// };

// export default PdfViewer;

// Core viewer

import { Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';

const PdfViewer: React.FC = () => {
  return (
    <>
      <style>
        {`
          .rpv-core__document {
            margin-bottom: 20px; /* Устанавливаем отступ между страницами */
          }
        `}
      </style>
      <div
        className="bg_pdf"
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          height: '100%',
          width: '100vw',

          background:
            // 'linear-gradient(rgb(44, 104, 158) 0%, rgba(42, 59, 66, 0.06) 100%)',
            'grey',
        }}
      >
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '100%',
            maxWidth: '800px',
            width: '100%',
            margin: '0 auto',
            background:
              'linear-gradient(rgb(44, 104, 158) 0%, rgba(42, 59, 66, 0.06) 100%)',
          }}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl="assets/Отчет_о_научно_исследовательской_работе_ВМА_им_Кирова.pdf" />
          </Worker>
        </div>
      </div>
    </>
  );
};
export default PdfViewer;
