import { PrombezCardTypes } from './prombezTypes';

export const dataPrombez: PrombezCardTypes[] = [
  {
    title: 'ПАО “СЕВМАШ”',
    text: 'Поставка и выполнение работ по пусконаладке анализаторов паров этанола в выдыхаемом воздухе АЛКОЗАМОК-П-02',
    img: 'assets/prombez_page/slider/01.jpg',
  },
  {
    title: 'ООО “ММСК”',
    text: 'Создание системы контроля периметра предприятия на базе всепогодных охранных извещателей STA',
    img: 'assets/prombez_page/slider/02.jpg',
  },
  {
    title: 'АО “Находкинский МТП”',
    text: 'Устройство проходной предприятия на базе биометрических терминалов контроля доступа с возможностью измерения температуры ZKTECO',
    img: 'assets/prombez_page/slider/image03.png',
  },
  {
    title: 'АО “НЗНП”',
    text: 'Устройство проходных предприятия путем интеграции анализаторов паров этанола в выдыхаемом воздухе АЛКОРАМКА с возможностью измерения температуры',
    img: 'assets/prombez_page/slider/04.jpg',
  },
  {
    title: 'ООО “УК “Колмар”',
    text: 'Устройство проходных предприятия путем интеграции анализаторов паров этанола в выдыхаемом воздухе АЛКОРАМКА и СКУД PARSEC',
    img: 'assets/prombez_page/slider/05.jpg',
  },
];
