import { MainMedicalEquipmentType } from '../types/medicineTypes';

import '../../assets/styles/medicine/plasmoran/plasmoran.scss';
interface DataProps {
  data: MainMedicalEquipmentType[];
}

export const ListFunction: React.FC<DataProps> = ({ data }) => {
  return (
    <div className="plasmoran_actual">
      {data.map((items, index) => (
        <div key={index} className="content_equiment_block">
          <h3 className="list_title_military">{items.title}</h3>
          <ul>
            {items.item.map((item, itemIndex) => (
              <li key={itemIndex} className="military_item">
                {item}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
