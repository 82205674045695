import { NavLink } from 'react-router-dom';

import '../../../../assets/styles/mainPage/decisions.scss';
import '../../../../assets/styles/mainPage/buttonAnimation.scss';
import '../../../../assets/styles/commonStyles.scss';

export function Decisions() {
  return (
    <>
      <section className="block_container decisions">
        <div className="wrapper">
          <h2 className="block_title left_padding__title">
            Интегрированные решения для промышленности и здравоохранения
          </h2>
          <div className="decisions_wrapper">
            <NavLink className="footer_link" to="/prombez">
              <div className="decis_alco common_decis_styles">
                <h4 className="decisions_text">
                  Инновационные решения для промышленной безопасности
                </h4>

                <button
                  className="button decisions_btn red-on-hover"
                  type="button"
                >
                  Подробнее
                </button>
              </div>
            </NavLink>
            <NavLink className="footer_link  " to="/medicine_main">
              <div className="decis_med common_decis_styles">
                <h4 className="decisions_text decis_med_text">
                  Комплексные решения в сфере здравоохранения
                </h4>
                <button
                  className="blue-on-hover button decisions_btn"
                  type="button"
                >
                  Подробнее
                </button>
              </div>
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
