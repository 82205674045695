import { Turnstile } from './Turnstile';
import {
  turnstileOma,
  turnstileOxgard,
  turnstilePerco,
  turnstilesLogo,
  turnstilesText,
} from './dataTurnstiles';

export const Turnstiles = () => {
  return (
    <section className="products">
      <Turnstile
        logoIndex={0}
        textIndex={0}
        images={turnstilePerco}
        texts={turnstilesText}
        logos={turnstilesLogo}
      />
      <Turnstile
        logoIndex={1}
        textIndex={1}
        images={turnstileOma}
        texts={turnstilesText}
        logos={turnstilesLogo}
      />
      <Turnstile
        logoIndex={2}
        textIndex={2}
        images={turnstileOxgard}
        texts={turnstilesText}
        logos={turnstilesLogo}
      />
    </section>
  );
};
