import React from 'react';
import { Routes, Route, useLocation } from 'react-router';

import { PrombezPage } from './components/prombezPage/PrombezPage';
import { MainPage } from './components/mainPage/MainPage';
import { AlcoframePage } from './components/alkoframePage/AlcoframePage';
import { ContactsPage } from './components/contactsPage/ContactsPage';
import { TurnstilesPage } from './components/turnstiles/TurnstilesPage';
import ScrollToTop from './components/utils/ScrollToTop';
import { AboutPage } from './components/about/AboutPage';
import { MainMedicinePage } from './components/medicine/mainMedicine/MainMedicinePage';
import { MedicinePlasmoranPage } from './components/medicine/plasmoran/MedicinePlasmoranPage';
import { FacilityPage } from './components/medicine/healthCareFacility/FacilityPage';
import { MedicineHardwarePage } from './components/medicine/medicineHardware/MedicineHardwarePage';
import { MedicineCarsPage } from './components/medicine/medicineCars/MedicineCarsPage';
import { MedicineClothesPage } from './components/medicine/clothes/MedicineClothesPage';
import { MedicineTechnicsPage } from './components/medicine/technics/MedicineTechnicsPage';
import { ProductsPage } from './components/products/ProductsPage';
import { Page404 } from './components/404Page/404Page';
import PdfViewer from './components/medicine/plasmoran/SciencePDF';
import { VideoPage } from './components/videoPage/VideoPage';

function App() {
  // const location = useLocation();
  // window.ym('номерсчетчика', 'hit', location.pathname);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <MainPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/prombez"
          element={
            <>
              <PrombezPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/alcoframe"
          element={
            <>
              <AlcoframePage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/contacts"
          element={
            <>
              <ContactsPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/turnstiles"
          element={
            <>
              <TurnstilesPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/products"
          element={
            <>
              <ProductsPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/about"
          element={
            <>
              <AboutPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="medicine_main"
          element={
            <>
              <MainMedicinePage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="medicine_plasmoran"
          element={
            <>
              <MedicinePlasmoranPage />
              <ScrollToTop />
            </>
          }
        ></Route>

        <Route
          path="medicine_equipment"
          element={
            <>
              <FacilityPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="medicine_clothes"
          element={
            <>
              <MedicineClothesPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="medicine_cars"
          element={
            <>
              <MedicineCarsPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="medicine_technics"
          element={
            <>
              <MedicineTechnicsPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="medicine_hardware"
          element={
            <>
              <MedicineHardwarePage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/projects"
          element={
            <>
              <Page404 />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/video"
          element={
            <>
              <VideoPage />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/it"
          element={
            <>
              <Page404 />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="medicine_it_products"
          element={
            <>
              <Page404 />
              <ScrollToTop />
            </>
          }
        ></Route>
        <Route
          path="/pdf"
          element={
            <>
              <PdfViewer />
              <ScrollToTop />
            </>
          }
        ></Route>
      </Routes>
    </>
  );
}

export default App;
