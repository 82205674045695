import { useLocation } from 'react-router-dom';

import OrderForm from './FooterForm';
import { FooterInfo } from './FooterInfo';
import { FooterMobileForm } from './FooterMobileForm';

import '../../assets/styles/header/headerForm.scss';
import '../../assets/styles/footer/footer.scss';

export function Footer() {
  const location = useLocation();

  const currentPath = location.pathname;
  let currentBg = '';
  let cerrentTitle =
    'Свяжитесь с нами сегодня, чтобы узнать больше о промышленной безопасности';
  let currentSrcCallMe = 'assets/call_me_red.png';
  if (currentPath.includes('/medicine')) {
    currentBg = 'medicine_main_footer_bg';
    cerrentTitle =
      'Свяжитесь с нами сегодня, чтобы узнать больше о инновациях в медицине';
    currentSrcCallMe = 'assets/call_me_blue.png';
  }
  if (window.innerWidth >= 768) {
    return (
      <footer className="footer">
        <div className={`task_form ${currentBg}`}>
          <h2 className="title footer_title">{cerrentTitle}</h2>
          <OrderForm />
        </div>
        <FooterInfo />
        {/* <aside className="call_me">
          <a href="tel:+78124267978">
            <img
              src="assets/call_me.png"
              alt="phone"
              className="call_me__icon"
            />
          </a>
        </aside> */}
      </footer>
    );
  } else {
    return (
      <footer className={`footer `}>
        <div className={`task_form ${currentBg}`}>
          <h2 className="title footer_title">
            Свяжитесь с нами сегодня, чтобы узнать больше о наших продуктах и
            услугах
          </h2>
          <FooterMobileForm className="call_form" />
        </div>
        <FooterInfo />
        <aside className="call_me">
          <a href="tel:+78124267978">
            <img src={currentSrcCallMe} alt="phone" className="call_me__icon" />
          </a>
        </aside>
      </footer>
    );
  }
}
