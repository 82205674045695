import { MainMedicalEquipmentType } from '../../types/medicineTypes';

import '../../../assets/styles/medicine/mainMedecine/mainMedicine.scss';

interface DataProps {
  data: MainMedicalEquipmentType;
}

export const FacilityPhase: React.FC<DataProps> = ({ data }) => {
  const currentPath = location.pathname;
  let list_style_img_url = '';
  let width = 'width';
  if (currentPath.includes('/medicine')) {
    list_style_img_url = 'blue_checkmark';
    width = '';
  }
  // if (currentPath.includes('/video')) {
  //   columns = 'columns';
  // }
  return (
    <div className="content_facility_block padding_bottom_50">
      <h3 className="block_text fac_title_hidden phase_title">{data.title}</h3>
      <ul>
        {data.item.map((item, itemIndex) => (
          <li
            key={itemIndex}
            className={`equipment_item block_text padding_bottom_0 ${list_style_img_url} ${width}`}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
