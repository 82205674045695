import React from 'react';

interface DataType {
  title: string;
  item: string;
}

export function PlasmoranScope({ data }: { data: DataType[] }) {
  return (
    <>
      <div className="block_container">
        <div className="wrapper ">
          <h2 className="block_title">Область применения</h2>
          <p className="block_text">
            Область применения «ПЛАЗМОРАН» включает широкий список направлений в
            медицине:
          </p>
          <div className="plasmoran_table">
            {data.map((row, rowIndex) => (
              <div key={rowIndex} className="plasmoran_table_item">
                <p
                  className="plasmoran_table_title"
                  style={{
                    paddingBottom: rowIndex === 6 ? '40px' : '20px',
                  }}
                >
                  {row.title}
                </p>
                <div className="plasmoran_table_desc">{row.item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
