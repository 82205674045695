import { Footer } from '../../footer/Footer';
import { HeaderTop } from '../../header/HeaderTop';
import { GetCalculation } from '../../prombezPage/content/getCalculation/GetCalculation';
import { GetImgs } from '../../utils/GetImgs';
import { FacilityPhase } from '../healthCareFacility/Facilityphase';
import { HardwareItemsList } from '../medicineHardware/HardwareItemsList';
import ClothesSlider from './ClothesSlider';
import {
  clothesCaruselData,
  indoorСlothes,
  outdoorСlothes,
  personalProtect,
  personalProtectImgs,
  personalProtectItems,
} from './clothesData';

import '../../../assets/styles/medicine/clothes/clothesStyles.scss';
import '../../../assets/styles/medicine/plasmoran/plasmoran.scss';

export function MedicineClothesPage() {
  return (
    <>
      <HeaderTop />
      <section className="block_container ">
        <div className="wrapper clothes">
          <h3 className="block_title">Медицинская одежда и СИЗ</h3>
          <p className="divider_text block_text padding_bottom_0">
            С начала 2020 г. компания зарекомендовала себя как один из крупных
            поставщиков средств индивидуальной защиты, медицинской одежды,
            медицинских расходных материалов. В рамках государственных
            контрактов реализованы поставки средств индивидуальной защиты в
            медицинские подразделения «Крымский Республиканский Центр Медицины
            катастроф и скорой помощи», государственные учреждения
            Санкт-Петербурга и Ленинградской области, в подразделения
            коммерческих клиник «СОГАЗ-Медицина», «СМ-Клиника».
            <span className="divider_medicine"></span>
          </p>
          <HardwareItemsList data={indoorСlothes} />
          <HardwareItemsList data={outdoorСlothes} />
        </div>
        <GetCalculation />
        <div className="wrapper clothes">
          <h3 className="block_title clothes_title">
            Медицинские средства индивидуальной защиты
          </h3>
          <FacilityPhase data={personalProtect} />
          <GetImgs data={personalProtectImgs} />
          <p className="block_text">
            При производстве одноразовых медицинских изделий используется
            <span className="blue_text"> гипоаллергенный материал</span>,
            который не вызывает раздражения у людей с высокой чувствительностью
            к различным материалам.
          </p>
          <p className="block_text">
            Одноразовая медицинская одежда достаточно комфортна в использовании,
            не сковывает движений при рабочем процессе, не пропускает влагу и
            защищает от внешних воздействий.
          </p>
          <p className="block_text padding_bottom_0">
            В нашем портфеле представлен большой ассортимент изделий из
            сертифицированных материалов (спанбонд, Tyvek,Томбой, Савуар и пр.):
          </p>
          <FacilityPhase data={personalProtectItems} />
          <ClothesSlider slides={clothesCaruselData} />
          <p className="block_text padding_top_50">
            Вся продукция имеет Регистрационные удостоверения, необходимые
            Сертификаты и протоколы испытаний.
          </p>
          <p className="block_text">
            Производство официально зарегистрировано в государственном реестре
            медицинских изделий и организаций, осуществляющих производство и
            изготовление медицинских изделий.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}
