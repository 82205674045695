import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import '../../assets/styles/header/headerMainStyles.scss';
import '../../assets/styles/commonStyles.scss';

interface Item {
  title: string;
  desc: string;
}

interface HeaderTypes {
  items: Item[];
}

export const HeaderBottom: React.FC<HeaderTypes> = ({ items }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/alcoframe') {
      const titleElement = document.querySelector('.header_title');
      console.log('titleElement', titleElement);
      if (titleElement) {
        const text = titleElement.textContent || '';
        const firstWord = text.trim().split(' ')[0];
        const restOfText = text.slice(firstWord.length);

        titleElement.innerHTML = `<span style="color: #E10B0B;">${firstWord}</span>${restOfText}`;
      }
    }
  }, [location.pathname]);

  let currentItem;
  let width;

  switch (location.pathname) {
    case '/':
      currentItem = items[0];
      width = 'home_header_title';
      break;
    case '/prombez':
      currentItem = items[1];
      width = 'prombez_header_title';
      break;
    case '/alcoframe':
      currentItem = items[2];
      width = 'alcoframe_header_title';
      break;
    case '/medicine_main':
      currentItem = items[3];
      width = 'prombez_header_title';
      break;
    case '/medicine_plasmoran':
      currentItem = items[4];
      width = 'prombez_header_title';
      break;
    default:
      currentItem = items[0];
      width = 'home_header_title';
  }

  const linkClassName =
    location.pathname === '/prombez' ||
    location.pathname === '/alcoframe' ||
    location.pathname.includes('medicine')
      ? ''
      : 'black_color';

  return (
    <div className="wrapper">
      <div className={`header_bottom ${width}`}>
        <h1 className={` ${linkClassName} header_title`}>
          {currentItem.title}
        </h1>
        <p className={`header_text ${linkClassName}`}>{currentItem.desc}</p>
      </div>
    </div>
  );
};
