import { AdvantagesTypes } from '../../../prombezPage/prombezTypes';

export const ourAdvdntagesData: AdvantagesTypes[] = [
  {
    title: 'Индивидуальный подход',
    img: 'assets/prombez_page/8.png',
    description:
      'Постоянное развитие предложения и индивидуальные отраслевые решения в соответствии с требованиями Заказчика',
  },
  {
    title: 'Надежность',
    img: 'assets/prombez_page/10.png',
    description:
      'Устойчивые деловые связи \n с компаниями-производителями \n(оборудование от 500+ мировых и российских производителей)',
  },
  {
    title: 'Российское ПО',
    img: 'assets/prombez_page/11.png',
    description:
      'Эффективные решения \n от российских вендоров в сфере программного обеспечения и ИТ-оборудования в рамках импортозамещения',
  },
  {
    title: 'Решения под ключ',
    img: 'assets/prombez_page/12.png',
    description: 'Построение комплексных решений для проектов под ключ',
  },
  {
    title: 'Цена',
    img: 'assets/prombez_page/13.png',
    description:
      'Гибкая ценовая политика за счет использования современных бизнес-моделей',
  },
  {
    title: 'Ассортимент',
    img: 'assets/prombez_page/14.png',
    description:
      'Наличие широкого ассортимента продукции на складах РФ и развитая система логистики обеспечивают минимальные сроки поставки и интеграции',
  },
];
