export function HowWorksPlasmoran() {
  return (
    <>
      <section className="block_container">
        <div className="wrapper">
          <h2 className="plasmoran_title block_title">
            Как работает <span className="blue_text">ПЛАЗМОРАН</span>
          </h2>

          <p className="block_text">
            Принцип действия оборудования основан на генерации
            низкотемпературной аргоновой плазмы. Ее воздействие на раневую
            поверхность активизирует биологические реакции организма,
            направленные на заживление поврежденных тканей.
          </p>

          <img
            src="assets/medicine/plasmoran/3ff1a001fb8e71fa598c99e55e2e127d.png"
            alt="img"
            className="plasmoran_img"
          />

          <p className="block_text padding_top_50">
            Лечение плазмой обеспечивает заживление ран практически в два раза
            быстрее по сравнению со стандартными методами.
          </p>
          <p className="block_text padding_bottom_0">
            Плазма представляет собой ионизированный газ высокой температуры,
            который состоит из заряженных частиц, свободных электронов, ионов,
            радикалов, инфракрасного, ультрафиолетового излучений. Кроме высокой
            температуры и электромагнитных полей, весомым фактором воздействия
            являются компоненты самой плазмы.
          </p>
        </div>
      </section>
    </>
  );
}
