export function PlasmoranEconomics() {
  return (
    <div className="block_container">
      <div className="wrapper ">
        <h2 className="block_title ">
          Экономический эффект применения оборудования «Плазморан»
        </h2>
        <img
          src="assets/medicine/plasmoran/Rectangle 4426.png"
          alt="img"
          className="plasmoran_img"
        />
        <p className="block_text not_padding">
          Ежедневно оборудование «Плазморан» позволяет проводить обработку ран у
          <span className="light_blue_text"> 40 и более пациентов,</span> то
          есть всех пациентов стандартного отделения гнойной хирургии.
        </p>

        <p className="block_text padding_top_50 padding_bottom_0">
          Затраты на эксплуатацию установки связаны с расходом рабочего газа
          аргона и потреблением электроэнергии. В совокупности они составляют
          <span className="light_blue_text"> 5-7 руб. на одну процедуру</span>.
        </p>
      </div>
    </div>
  );
}
