import React from 'react';

import { MainTypes } from '../../../prombezPage/prombezTypes';

import '../../../../assets/styles/prombez/innovation.scss';

interface DataProps {
  data: MainTypes[];
}

const InnovationTable: React.FC<DataProps> = ({ data }) => {
  const currentPath = location.pathname;
  let innovationTitle = '';
  let advBlock = '';
  let medicineInnovations = '';
  let medicineDivider = '';

  if (currentPath.includes('/medicine')) {
    innovationTitle = 'blue_innovation_title';
    advBlock = 'blue_advantages_block';
    medicineInnovations = 'medicine_innovations';
    medicineDivider = 'medicine_divider';
  }

  return (
    <div className={`advantages_block  ${advBlock}`}>
      {data.map((list, index) => (
        <React.Fragment key={index}>
          <div className={`content_block ${medicineInnovations}`}>
            <h3 className={`innovation_title ${innovationTitle}`}>
              {list.title}
            </h3>
            <p className="innovation_text ">{list.desc}</p>
          </div>
          <div className={`divider ${medicineDivider}`}></div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default InnovationTable;
