import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Button, Container } from '@mui/material';

import { Navigation } from './Navigation';
import { RouteTypes } from './routeTypes';
import { HeaderForm } from './HeaderForm';
import ModalWindow from '../utils/modalWindow';
import { SuccessMessage } from '../utils/SuccessMessage';

import '../../assets/styles/header/headerMainStyles.scss';
import '../../assets/styles/header/headerForm.scss';
import '../../assets/styles/styleUtils/btnsHover.scss';

export function HeaderTop() {
  const location = useLocation();
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const routeSrc: RouteTypes = {
    '/': 'assets/main_page/black_logo.png',
    '/alcoframe': 'assets/prombez_page/logo.png',
    '/prombez': 'assets/prombez_page/logo.png',
    '/medicine_main': 'assets/prombez_page/logo.png',
    '/projects': 'assets/prombez_page/logo.png',
    '/it': 'assets/prombez_page/logo.png',
    '/medicine_it_products': 'assets/prombez_page/logo.png',
  };

  const currentRouteSrc =
    routeSrc[location.pathname] || 'assets/main_page/black_logo.png';

  const currentPath = location.pathname;
  let currentButtonColor = '';
  let currentButtonHoverColor = 'red_btn_hover';
  if (currentPath.includes('/medicine')) {
    currentButtonHoverColor = 'blue_btn_hover';
    currentButtonColor = 'blue_btn';
  }
  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };
  const onFormSubmitAction = () => {
    setIsSuccessModalOpen(true);
    setIsFormModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsFormModalOpen(false);
  };
  console.log('ismodalopen form', isFormModalOpen);

  return (
    <div className="header_container block_container">
      <Link to="/">
        <img src={`${currentRouteSrc}`} alt="logo" className="logo" />
      </Link>
      <Navigation />
      <button
        className={`button header_btn ${currentButtonHoverColor} ${currentButtonColor}`}
        onClick={handleOpenModal}
      >
        Связаться с нами
      </button>
      <ModalWindow isOpen={isFormModalOpen} onClose={handleCloseModal}>
        <div className="modal_content">
          <Container className="">
            <Button
              id="cross_button"
              className="header_form_button_close"
              style={{
                position: 'absolute',
                left: '444px',
                top: '3%',
                outline: 'none!important',
              }}
              onClick={handleCloseModal}
            >
              <img
                src="assets/icons/cross-icon.svg"
                alt="cross-icon"
                style={{ width: 30 }}
              />
            </Button>
            <HeaderForm onFormSubmitAction={onFormSubmitAction} />
          </Container>
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
      >
        <SuccessMessage handleCloseModal={handleCloseSuccessModal} />
      </ModalWindow>
    </div>
  );
}
