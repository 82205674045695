import { dataPrombez } from '../../dataPrombez';
import { PrombezSlider } from './PrombezSlider';

export function Prombez() {
  return (
    <section className="block_container prombez_slider">
      <PrombezSlider data={dataPrombez} />
    </section>
  );
}
