import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { useMediaQuery } from '@mui/material';

import ModalWindow from '../../../utils/modalWindow';
import { PHONENUMBERREGEXP, REQUIRED } from '../../../utils/variables';
import { onSubmit } from '../../../utils/formFunctions';
import { FormDataType } from '../../../types/iksTypes';
import { SuccessMessage } from '../../../utils/SuccessMessage';

import '../../../../assets/styles/prombez/getCalculation.scss';

interface GetCalculationType {
  hideClassName?: string;
}

export function GetCalculation(props: GetCalculationType) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    resetField,
    formState: { errors },
  } = useForm<FormDataType>({
    defaultValues: {
      phone: '',
    },
  });

  const matches = useMediaQuery('(max-width: 768px)');

  const formId = 'get_calculation_form';

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const resetForm = () => {
    resetField('phone');
  };

  const handleFormSubmit = () => {
    handleSubmit((formData) =>
      onSubmit(formData, resetForm, handleOpenModal, formId)
    )();
  };

  const currentPath = location.pathname;
  let getCalculationBg = '';
  let getCalcBg = '';
  let getCulcInput = '';
  let phoneIconColor = '#807c7c';
  let getCulcButn = 'red_btn_hover';

  if (currentPath.includes('/medicine')) {
    getCalculationBg = 'get_calculation_blue';
    getCalcBg = 'get_calc_blue';
    getCulcInput = 'get_calc_blue_input';
    phoneIconColor = '#fff';
    getCulcButn = 'get_calc_btn_blue blue_btn_hover';
  }
  let harddwareMargin = '';
  let helperTextColor = 'rgb(128, 124, 124)';
  if (currentPath.includes('/medicine')) {
    harddwareMargin = 'get_calculation_hardware_medicine';
    helperTextColor = '#fff';
  }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div
      className={`block_container get_calculation ${getCalculationBg} ${harddwareMargin} ${props.hideClassName}`}
    >
      <div className={`get_calc ${getCalcBg}`}>
        <img
          src="assets/prombez_page/16.svg"
          alt="icon"
          className="get_calc_img"
        />
        <p className="calc_text">
          Проект и просчет системы безопасности Вашего Бизнеса бесплатно!
        </p>
        <form
          id="get_calculation_form"
          onSubmit={handleSubmit(handleFormSubmit)}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            minWidth: '129px',
            maxWidth: '630px',
            height: '49px',
            minHeight: '16px',
            gap: '10%',
          }}
        >
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                className={`get_calc_form ${getCulcInput}`}
                placeholder={isFocused ? '' : 'Ваш номер телефона'}
                {...field}
                onFocus={handleFocus}
                InputProps={{
                  onBlur: () => {
                    field.onBlur();
                    handleBlur();
                  },
                  startAdornment: (
                    <InputAdornment position="start" style={{ color: '#fff' }}>
                      <PhoneInTalkIcon
                        style={{
                          color: phoneIconColor,
                          paddingRight: '10px',
                          width: '120%',
                        }}
                      />
                      {isFocused && '+7 '}
                    </InputAdornment>
                  ),
                  style: { color: '#fff' },
                }}
                fullWidth
                variant="standard"
                margin="normal"
                {...register('phone', {
                  required: REQUIRED,
                  pattern: {
                    value: PHONENUMBERREGEXP,
                    message: 'Введите номер телефона в формате "+75553523535"',
                  },
                })}
                error={!!errors.phone}
                helperText={
                  errors.phone && (
                    <span
                      style={{
                        fontSize: matches ? '8px' : '12px',
                        lineHeight: matches ? '8px' : '12px',
                        height: '15px',
                        display: 'flex',
                        paddingTop: '10px',
                      }}
                    >
                      {errors.phone.type === 'required'
                        ? REQUIRED
                        : 'Введите номер телефона в формате "+75553523535"'}
                    </span>
                  )
                }
                sx={{
                  width: '360px',
                  minWidth: '129px',
                  height: '49px',
                  minHeight: '16px',
                  color: '#C8C1F1',
                  boxShadow: '0px 2px 22px 0px rgba(0, 0, 0, 0.12)',
                  borderRadius: '5px',
                  background: 'rgba(255, 255, 255, 0.20)',
                  '& input::placeholder': {
                    color: helperTextColor,
                  },
                }}
              />
            )}
          />

          <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal}>
            <SuccessMessage handleCloseModal={handleCloseModal} />
          </ModalWindow>
          <button className={`button get_calc_btn ${getCulcButn}`}>
            Получить расчет
          </button>
        </form>
      </div>
    </div>
  );
}
