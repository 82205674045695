import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { NavLinksTypes } from '../types/medicineTypes';
import { SideBar } from './SideBarMenu';

import '../../assets/styles/header/nav.scss';

export function Navigation() {
  const location = useLocation();

  const linkClassName: NavLinksTypes = {
    '/': 'black_link',
    '/about': 'black_link',
    '/products': 'black_link',
    '/projects': 'black_link',
    '/turnstiles': 'black_link',
    '/contacts': 'black_link',
    '/medicine_plasmoran': 'blue_link',
    '/medicine_equipment': 'blue_link',
    '/medicine_clothes': 'blue_link',
    '/medicine_cars': 'blue_link',
    '/medicine_technics': 'blue_link',
    '/medicine_hardware': 'blue_link',
    '/medicine_main': 'white_link',
    '/prombez': 'white_link',
    '/alcoframe': 'white_link',
  };

  const currentClassName = linkClassName[location.pathname] || '';

  const currentPath = location.pathname;
  let currentLinkHover = '';
  if (currentPath.includes('/medicine')) {
    currentLinkHover = 'blue_hover';
  }

  return (
    <>
      <nav className="nav">
        <NavLink
          className={`link ${currentClassName} ${currentLinkHover}`}
          to="/about"
        >
          О нас
        </NavLink>
        <NavLink
          to="/products"
          className={`link ${currentClassName} ${currentLinkHover} `}
        >
          Продукты
        </NavLink>
        <NavLink
          to="/projects"
          className={`link ${currentClassName} ${currentLinkHover}`}
        >
          Проекты
        </NavLink>
        <NavLink
          to="/contacts"
          className={`link ${currentClassName} ${currentLinkHover}`}
        >
          Контакты
        </NavLink>
      </nav>
      <SideBar
        isOpen={false}
        right={true}
        pageWrapId={'page-wrap'}
        outerContainerId={'App'}
      />
    </>
  );
}
