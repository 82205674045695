import { AlcoBenefitsTypes, AlcoControlTypes, AlcoTypes } from './alcoTypes';

export const dataAlco: AlcoTypes[] = [
  {
    num: '1',
    text: 'Секунда теста',
  },
  {
    num: '25',
    text: 'Человек в минуту',
  },

  {
    num: '24/7',
    text: 'Круглосуточная работа',
  },
  {
    num: '7',
    text: 'Лет бесперебойной работы',
  },
];

export const alcoBenefits: AlcoBenefitsTypes[] = [
  {
    ico: 'assets/alco_frame/icons/clock.svg',
    title: 'ЭКСПРЕСС-ТЕСТ: РЕЗУЛЬТАТ ЗА 1 СЕКУНДУ',
    text: 'Всего 1 секунду занимает алкотестирование и ровно через 1 секунду устройство готово к новому тесту',
  },
  {
    ico: 'assets/alco_frame/icons/roundarrow.png',
    title: 'КРУГЛОСТОЧНАЯ РАБОТА',
    text: 'Работает круглосуточно в автономном режиме. Автоматически срабатывает на выдох и наличие паров этанола',
  },
  {
    ico: 'assets/alco_frame/icons/Vector.svg',
    title: 'СВЕРХТОЧНЫЕ ПОКАЗАТЕЛИ ',
    text: 'Работает на базе уникальной технологии, измеряет сверхточно и без ошибок',
  },
  {
    ico: 'assets/alco_frame/icons/checkpoint.svg',
    title: 'ИНТЕГРАЦИЯ С НОВОЙ И СУЩЕСТВУЮЩЕЙ СКУД',
    text: 'Легко адаптируется к действующей системе безопасности и встраивается в любую инфраструктуру',
  },
  {
    ico: 'assets/alco_frame/icons/folder.svg',
    title: 'СОЗДАНИЕ ЕДИНОЙ БАЗЫ ВСЕХ ТЕСТОВ',
    text: 'Специальное ПО формирует базу данных с нескольких приборов, анализирует и создает отчеты',
  },
  {
    ico: 'assets/alco_frame/icons/person.svg',
    title: 'ИНДИВИДУАЛЬНЫЕ НАСТРОЙКИ ',
    text: 'Настраивается с учетом пожеланий клиента на заданный порог срабатывания и другие параметры',
  },
  {
    ico: 'assets/alco_frame/icons/maintenance.svg',
    title: 'ТЕХНИЧЕСКОЕ ОБСЛУЖИВАНИЕ',
    text: 'Требуется лишь периодическая чистка зеркал. Снабжено системой самодиагностики',
  },
  {
    ico: 'assets/alco_frame/icons/Group.svg',
    title: 'ВЫСОКАЯ ПРОПУСКНАЯ СПОСОБНОСТЬ',
    text: 'Измеряет в потоковом режиме с высокой пропускной способностью до 25 человек в минуту',
  },
  {
    ico: 'assets/alco_frame/icons/ruble.svg',
    title: 'НЕТ РАСХОДОВ НА ЭКСПЛУАТАЦИЮ',
    text: 'Не требует покупки мундштуков, калибровки, замены датчиков',
  },
  {
    ico: 'assets/alco_frame/icons/medicine.svg',
    title: 'ИЗМЕРЕНИЯ ПРИ НАЛИЧИИ МЕДИЦИНСКОЙ МАСКИ',
    text: 'Нет необходимости снимать маску при прохождении тестирования',
  },
  {
    ico: 'assets/alco_frame/icons/analytics.svg',
    title: 'ПОДРОБНЫЙ ОТЧЕТ, АНАЛИТИКА И СТАТИСТИКА',
    text: 'Ежедневно сохраняет результаты тестов при интеграции в СКУД',
  },
  {
    ico: 'assets/alco_frame/icons/time.svg',
    title: 'ДЛИТЕЛЬНЫЙ СРОК СЛУЖБЫ ',
    text: 'Работает без остановки 7 лет, имеет расширенную двухлетнюю гарантию',
  },
];

export const dataAlcoConrol: AlcoControlTypes[] = [
  {
    title: 'АВАРИЙНЫЕ СИТУАЦИИ',
    text: 'Поломка дорогого оборудования – частое последствие действий нетрезвого работника, которое влечет аварийную остановку производства.',
  },
  {
    title: 'ФИНАНСОВЫЕ ПОТЕРИ',
    text: 'Статья 5.27 КоАП РФ гласит, что штраф за нарушение требований охраны труда накладывается исключительно на работодателя.',
  },
  {
    title: 'ПРОИЗВОДСТВЕННЫЕ ТРАВМЫ',
    text: 'Нетрезвые работники наносят себе различные травмы: падение с высоты, удар электротоком, физические увечья. Они являются угрозой для себя и окружающих.',
  },

  {
    title: 'ЮРИДИЧЕСКАЯ ОТВЕТСТВЕННОСТЬ',
    text: 'Согласно ст. 76 ТК РФ работодатель обязан отстранить от работы сотрудника, появившегося в алкогольном опьянении',
  },
  {
    title: 'РЕПУТАЦИОННЫЕ РИСКИ',
    text: 'Нетрезвые сотрудники порочат компанию, как внутри коллектива, так и во внешнем деловом сообществе.',
  },
  {
    title: 'МОРАЛЬНЫЙ ВРЕД',
    text: 'Внештатные ситуации неминуемо влекут нарушение морального климата в коллективе и потрясения для работодателя.',
  },
];
