import React, { useRef, useState } from 'react';

import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { PrombezCardTypes } from '../../prombezTypes';
import { PrombezCard } from './PrombezCard';

import '../../../../assets/styles/prombez/prombez.scss';

SwiperCore.use([Navigation, Pagination]);

interface PrombezProps {
  data: PrombezCardTypes[];
}
export const PrombezSlider: React.FC<PrombezProps> = ({ data }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const swiperRef = useRef(null);
  const [isPrevButtonActive, setPrevButtonActive] = useState(false);
  const [isNextButtonActive, setNextButtonActive] = useState(true);

  const handleSlideChange = (swiper: SwiperCore) => {
    setPrevButtonActive(!swiper.isBeginning);
    setNextButtonActive(!swiper.isEnd);
  };

  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      disableOnInteraction: false,
    },
    loop: true,
    speed: 1500,
    spaceBetween: 60,
    navigation: {
      nextEl: '.btn_next',
      prevEl: '.btn_prev',
    },
    on: {
      slideChange: () =>
        handleSlideChange(swiperRef.current || ({} as SwiperCore)),
    },
  };

  const currentPath = location.pathname;
  let btnNextBlue = '';
  let btnPrevBlue = '';
  const paginationBullets: NodeListOf<HTMLElement> = document.querySelectorAll(
    '.swiper-pagination-bullet'
  );

  if (currentPath.includes('/medicine')) {
    btnNextBlue = 'btn_next_blue';
    btnPrevBlue = 'btn_prev_blue';
    if (paginationBullets !== null) {
      paginationBullets.forEach((paginationBullet) => {
        paginationBullet.style.backgroundColor = 'rgb(44, 104, 158)';
      });
    }
  }

  const prombezSwiperId = 'promdezId';

  return (
    <div className="swiper_card_container">
      <Swiper id={prombezSwiperId} {...params} onSwiper={setSwiper}>
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <PrombezCard
              key={index}
              title={item.title}
              text={item.text}
              img={item.img}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="arrows_slider prombez_arrows">
        <button className={`btn_prev ${btnNextBlue}`}></button>
        <button className={`btn_next ${btnPrevBlue}`}></button>
      </div>
      <div
        className={`swiper-pagination pagination-container prombez_pagination `}
      ></div>
    </div>
  );
};
