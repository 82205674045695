import { ProductsTypes } from './productsTypes';

export const dataMedicines: ProductsTypes[] = [
  {
    title: 'Плазморан',
    products: '',
    link: '/medicine_plasmoran',
  },
  {
    title: 'Комплексное оснащение  лечебно - профилактических учреждений',
    products:
      'Операционные, Реанимационные, Диагностика, Кардиология, Урология, Гинекология, Реабилитация',
    link: '/medicine_equipment',
  },
  {
    title: 'Медицинское оборудование',
    products: 'Видеокамеры, тепловизоры, регистраторы, система видеоаналитики',
    link: '/medicine_hardware',
  },
  {
    title: 'Медицинская одежда и СИЗ',
    products:
      'Одежда и обувь, защитные комбинезоны, халаты, фартуки, бахилы, нарукавники, тапочки, шлемы, маски, респираторы, перчатки,  очки,  экраны,  щитки',
    link: '/medicine_clothes',
  },
  {
    title: 'Современные машины скорой медицинской помощи',
    products: 'Автомобиль скорой помощи на базе УАЗ Профи',
    link: '/medicine_cars',
  },
  {
    title: 'Медицинские IT-продукты',
    products: 'Электронная система медицинских осмотров',
    link: '/medicine_it_products',
  },
  {
    title: 'Техническое обслуживание',
    products:
      'Восстановление рентгеновских трубок, Диагностика и устранение неисправностей',
    link: '/medicine_technics',
  },
];

export const dataProducts: ProductsTypes[] = [
  {
    title: 'Алкорамка',
    products: '',
    link: '/alcoframe',
  },
  {
    title: 'Системы контроля управления доступом',
    products:
      'Турникеты, Металлодетекторы, Считыватели, контроллеры, программное обеспечение',
    link: '/turnstiles',
  },
  {
    title: 'Системы видеонаблюдения',
    products: 'Видеокамеры, тепловизоры, регистраторы, система видеоаналитики',
    link: '/video',
  },
  {
    title: 'IT оборудование',
    products:
      'Серверы и системы хранения данных, Телекоммуникационное и сетевое оборудование, компьютеры, ноутбуки, планшеты, Комплектующие для ПК и серверов, Оргтехника и расходные материалы, Программное обеспечение',
    link: '/it',
  },
];
