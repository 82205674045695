import { NavLink } from 'react-router-dom';
import { MainMedicalEquipmentTypes } from '../../types/medicineTypes';

import '../../../assets/styles/medicine/mainMedecine/mainMedicine.scss';

interface DataProps {
  data: MainMedicalEquipmentTypes[];
}

export const MainMedicalItems: React.FC<DataProps> = ({ data }) => {
  return (
    <div className="equipment_block">
      {data.map((items, index) => (
        <div key={index} className="content_equipment_block">
          <NavLink className="equipment_link" to={items.link}>
            <h3 className="content_equipment_title">{items.title}</h3>
            <ul>
              {items.item.map((item, itemIndex) => (
                <li key={itemIndex} className="equipment_item">
                  {item}
                </li>
              ))}
            </ul>
          </NavLink>
        </div>
      ))}
    </div>
  );
};
