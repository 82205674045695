import { SafetyTypes } from '../../../prombezPage/prombezTypes';

export const controlData: SafetyTypes[] = [
  {
    title: 'Системы контроля управления доступом',
    item: [
      'Турникеты',
      'Металлодетекторы',
      'Считыватели',
      'Контроллеры',
      'Программное обеспечение',
    ],
    img: 'assets/prombez_page/image1.png',
  },
];

export const videoData: SafetyTypes[] = [
  {
    title: 'Системы видеонаблюдения',
    item: [
      'Видеокамеры',
      'Тепловизоры',
      'Регистраторы',
      'Система видеоаналитики',
    ],
    img: 'assets/prombez_page/image2.png',
  },
];

export const itData: SafetyTypes[] = [
  {
    title: 'IT оборудование',
    item: [
      'Серверы и системы хранения данных',
      'Телекоммуникационное и сетевое оборудование',
      'Компьютеры, ноутбуки, планшеты',
      'Комплектующие для ПК и серверов',
      'Оргтехника и расходные материалы',
      'Программное обеспечение',
    ],
    img: 'assets/prombez_page/image3.png',
  },
];
