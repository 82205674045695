import React from 'react';

import { AdvantagesTypes } from '../../../prombezPage/prombezTypes';

import '../../../../assets/styles/prombez/ourAdvantages.scss';

interface DataProps {
  data: AdvantagesTypes[];
}

const AdvantagesList: React.FC<DataProps> = ({ data }) => {
  const currentPath = location.pathname;
  let currentTitleBg = '';
  let advMargin = '';
  if (currentPath.includes('/medicine')) {
    currentTitleBg = 'adv_title_medicine_item';
    advMargin = 'adv_margin';
  }
  return (
    <div className={`advantages_list ${advMargin}`}>
      {data.map((item, index) => (
        <div key={index} className="advantages_item">
          <img src={item.img} alt={item.title} className="advantages_img" />
          <h5 className={`advantages_list_title ${currentTitleBg}`}>
            {item.title}
          </h5>
          <p className="adv_description">{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default AdvantagesList;
