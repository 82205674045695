import { MainTypes } from '../../../prombezPage/prombezTypes';

export const innovationData: MainTypes[] = [
  {
    title: '8 лет',
    desc: 'на рынке промышленной безопасности',
  },
  {
    title: '80+ ',
    desc: 'Алкорамок интегрировано на проходных',
  },
  {
    title: '600+',
    desc: 'оборудовано точек видеонаблюдения',
  },
  {
    title: '500+',
    desc: 'вендоров в портфеле',
  },
];


export const medicineInnovationData: MainTypes[] = [
{
  title: '5+',
  desc: 'лет на рынке',
},
{
  title: '50+ ',
  desc: 'успешных контрактов в рамках 44-ФЗ',
},
{
  title: '3,2 млн +',
  desc: 'средств индивидуальной защиты поставлено во время COVID-19',
},
{
  title: '90+',
  desc: 'единиц оборудования поставлено в государственные медицинские учреждения',
},
]