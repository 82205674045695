import { NavLink } from 'react-router-dom';

import '../../../../assets/styles/prombez/alсoframe.scss';

export function Alсoframe() {
  return (
    <>
      <section className="block_container alсoframe">
        <h2 className="block_title alko_title">
          Анализатор паров этанола
          <span className="red_word">&nbsp;АЛКОРАМКА</span>
        </h2>
        <div className="alco_content">
          <div className="alco_bgi">
            <div className="alco_description">
              <article className="header_text alco_text">
                Алкорамка предназначена для оперативного контроля персонала на
                предмет употребления алкоголя. С ее помощью наши заказчики
                сохраняют денежные средства и репутацию компании, обеспечивают
                высокие показатели безопасности и снимают риски
                производственного травматизма.
              </article>

              <NavLink className="footer_link" to="/alcoframe">
                <button
                  className="red-on-hover button decisions_btn"
                  type="button"
                >
                  Подробнее
                </button>
              </NavLink>
            </div>
          </div>
          <div className="alco_img">
            <div className="alco_img_bg"></div>
            <img src="assets/alco_frame/alcoframe.png" alt="alcoframe" />
          </div>
        </div>
      </section>
    </>
  );
}
