import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { slide as Menu, Props } from 'react-burger-menu';
import { Button, Container } from '@mui/material';

import ModalWindow from '../utils/modalWindow';
import { HeaderForm } from './HeaderForm';
import { SuccessMessage } from '../utils/SuccessMessage';

import '../../assets/styles/header/sideBarMenu.scss';

export function SideBar(
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Menu> &
    Readonly<Props>
) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    console.log('modal window is open');
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };
  const onFormSubmitAction = () => {
    setIsSuccessModalOpen(true);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const sideBarIcon: HTMLElement | null =
      document.querySelector('.bm-burger-button');
    if (location.pathname.includes('/medicine') && sideBarIcon !== null) {
      sideBarIcon.style.background = 'rgb(44, 104, 158)';
    }
  }, []);

  return (
    <Menu {...props}>
      <Link to="/" className="sidebar_logo-link">
        <img
          src="assets/main_page/black_logo.png"
          alt="logo"
          className="sidebar_logo"
        />
      </Link>
      <div className="sidebar_divider"></div>
      <NavLink className="sidebar_item" to="/"></NavLink>
      <NavLink to="/about" className="sidebar_item">
        О нас
      </NavLink>
      <NavLink to="/products" className="sidebar_item">
        Продукты
      </NavLink>
      <NavLink to="/projects" className="sidebar_item">
        Проекты
      </NavLink>
      <NavLink to="/contacts" className="sidebar_item">
        Контакты
      </NavLink>
      <NavLink to="tel:+78124267978" className="sidebar_phone">
        +7 (812) 426-79-78
      </NavLink>
      <button
        className={`button header_btn side_bar_btn`}
        onClick={handleOpenModal}
      >
        Связаться с нами
      </button>
      <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="modal_content">
          <Container>
            <Button
              style={{
                position: 'absolute',
                top: '4%',
                left: 444,
                outline: 'none!important',
              }}
              onClick={handleCloseModal}
            >
              <img
                src="assets/icons/cross-icon.svg"
                alt="cross-icon"
                style={{ width: 30 }}
              />
            </Button>
            <HeaderForm onFormSubmitAction={onFormSubmitAction} />
          </Container>
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
      >
        <SuccessMessage handleCloseModal={handleCloseSuccessModal} />
      </ModalWindow>
    </Menu>
  );
}
