import React from 'react';

import { AlcoTypes } from '../../alkoframePage/alcoTypes';

import '../../../assets/styles/medicine/plasmoran/plasmoran.scss';

interface DataProps {
  data: AlcoTypes[];
}

export const PlasmoranAdvantages: React.FC<DataProps> = ({ data }) => {
  return (
    <section className="block_container ">
      <div className="wrapper plasmoran_wrapper">
        <div className="advantages_block plasmo_adv">
          {data.map((list, index) => (
            <React.Fragment key={index}>
              <div className="content_block plasmo_content_block">
                <h3 className="plasmo_adv_title ">{list.num}</h3>
                <p className=" plasmo_adv_text">{list.text}</p>
              </div>
              <div className="divider plasmo_divider"></div>
            </React.Fragment>
          ))}
        </div>
        <p className="plasmo_description block_text">
          Плазменно-дуговая хирургическая установка «ПЛАЗМОРАН» разработана для
          ускоренного заживления ран и лечения инфицированных осложнений.
          Оборудование значительно повышает эффективность лечения
          гнойно-некротических и воспалительных повреждений мягких тканей
          различной этиологии.
        </p>
      </div>
    </section>
  );
};
