import { FacilityType } from '../../types/medicineTypes';

export const aboutUsData: FacilityType[] = [
  {
    img: 'assets/about_page/Сертификат__ЦИЗИЛ.jpg',
  },
  {
    img: 'assets/about_page/sertifikat_perco_iks.jpg',
  },
  {
    img: 'assets/about_page/sertifikat_rosprom.jpg',
  },
  {
    img: 'assets/about_page/gazprom.jpg',
  },
  {
    img: 'assets/about_page/blagodarnost_sovet.jpg',
  },
  {
    img: 'assets/about_page/Свидетельство__ЦИЗИЛ.jpg',
  },
];
