import { AlcoTypes } from '../../alkoframePage/alcoTypes';
import {
  MainMedicalEquipmentType,
  PlasmoranType,
} from '../../types/medicineTypes';

export const plasmoAdvantagesData: AlcoTypes[] = [
  {
    num: '100%',
    text: 'стерилизация',
  },
  {
    num: '30-50%',
    text: 'быстрее скорость заживления ран',
  },

  {
    num: '2-2,5 раза',
    text: 'сокращение рисков ампутаций и летального исхода',
  },
  {
    num: '1,5-2 раза',
    text: 'возрастает объем оказываемой помощи',
  },
];

export const plasmoScopeData: PlasmoranType[] = [

  {
    title: 'ЭНДОКРИНОЛОГИЯ',
    item: 'Лечение диабетических язв',
  },
  {
    title: 'ГНОЙНАЯ ХИРУРГИЯ',
    item: 'Лечение инфицированных и хронических ран различной этиологии',
  },
  {
    title: 'ОЖОГОВАЯ ХИРУРГИЯ',
    item: 'Лечение термических ран',
  },

  {
    title: 'СОСУДИСТАЯ ХИРУРГИЯ И ФЛЕБОЛОГИЯ',
    item: 'Лечение сосудистых язв', 
  },
  {
    title: 'ВОЕННО-ПОЛЕВАЯ ХИРУРГИЯ',
    item: 'Лечение боевых ранений открытого типа',
  },
  {
    title: 'ЧЕЛЮСТНО-ЛИЦЕВАЯ ХИРУРГИЯ',
    item: 'Лечение гнойно-воспалительных процессов в области голова-шея',
  },
  {
    title: 'КОЛОПРОКТОЛОГИЯ',
    item: 'Бесшовная геморроидэктомия, лечение анальных трещин, свищей, парапроктитов, острых и хронических воспалений эпителиального копчикового хода',
  },
  {
    title: 'ТРАВМАТОЛОГИЯ И ОРТОПЕДИЯ',
    item: 'Лечение сочетанных и инфицированных механических травм мягких тканей, применение при эндопротезировании и остеосинтезе',
  },

  {
    title: 'ДЕРМАТОЛОГИЯ И КОСМЕТОЛОГИЯ',
    item: 'Лечение острых и хронических заболеваний кожи и подкожной клетчатки, ревитализация и лифтинг',
  },

];

export const plasmoMilitaryData_1: MainMedicalEquipmentType[] = [
  {
    title: 'Установка «ПЛАЗМОРАН» актуальна в следующих клинических случаях:',
    item: [
      'при постоперационном лечении боевых ранений (стационарно и амбулаторно);',
      'при вторичных хирургических операциях;',
      'при лечении медленно заживающих ран, гнойно-некротических и инфекционных осложнений, а также обширных ранений с большой площадью поражения мягких тканей;',
      'при возникновении рецидивов, лечении абсцессов и нагноений.',
    ],
  },
];

export const plasmoMilitaryData_2: MainMedicalEquipmentType[] = [
  {
    title: 'Применение «ПЛАЗМОРАН» целесообразно:',
    item: [
      'в специализированных госпиталях;',
      'в областных медицинских учреждениях, участвующих в программе реабилитации военных.',
    ],
  },
];
