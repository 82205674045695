import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { Activity } from './content/activity/Activity';
import { Decisions } from './content/decisions/Decisions';

export function MainPage() {
  return (
    <>
      <Header />
      <Decisions />
      <Activity />
      <Footer />
    </>
  );
}
