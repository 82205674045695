import { FormDataType } from '../types/iksTypes';

export const onSubmit = async (
  formData: FormDataType,
  resetForm: () => void,
  handleOpenModal: () => void,
  formId: string
) => {
  try {
    formData.formId = formId;
    console.log('formData.formId', formData.formId);
    // const response = await fetch('https://cz69124.tw1.ru/send_email.php', {
    const response = await fetch('send_email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      resetForm();
      handleOpenModal();
    } else {
      alert('Ошибка при отправке письма.');
    }
  } catch (error) {
    console.error('Ошибка:', error);
  }
};
