import { ProductsTypes } from './turnstilesTypes';

import '../../assets/styles/turnstiles/turnstilesStyles.scss';

export const Turnstile: React.FC<ProductsTypes> = ({
  logoIndex,
  textIndex,
  images,
  logos,
  texts,
}) => {
  return (
    <div className="product_card">
      <img src={logos[logoIndex].logo} alt="logo" className="turntiles_logo" />
      <p className="block_text padding_top_50">{texts[textIndex].text}</p>
      <div className="product_items">
        {images.map((image, index) => (
          <div key={index} className="product_item">
            <img src={image.img} className="products_img" alt="img" />
          </div>
        ))}
      </div>
    </div>
  );
};
