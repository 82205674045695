import { NavLink } from 'react-router-dom';

import '../../../assets/styles/medicine/mainMedecine/mainMedicine.scss';
import '../../../assets/styles/prombez/alсoframe.scss';

export function MainMedicinePlasmoran() {
  return (
    <>
      <section className="block_container alсoframe plasmo_section">
        <h2 className="block_title alko_title">
          Хирургическая установка
          <span className="blue_word">&nbsp;ПЛАЗМОРАН</span>
        </h2>
        <div className="plasmo_content">
          <div className=" plasmo_bgi">
            <div className="alco_description">
              <article className="header_text alco_text">
                Плазменно-дуговая хирургическая установка «Плазморан»
                разработана для ускоренного заживления ран и лечения
                инфицированных осложнений. Оборудование значительно повышает
                эффективность лечения гнойно-некротических и воспалительных
                повреждений мягких тканей различной этиологии.
              </article>
              <NavLink className="footer_link" to="/medicine_plasmoran">
                <button
                  className="blue-on-hover button decisions_btn"
                  type="button"
                >
                  Подробнее
                </button>
              </NavLink>
            </div>
          </div>
          <div className="alco_img">
            <div className="plasmo_img_bg"></div>
            <img
              src="assets/medicine/main_medicine/plasmoran.png"
              alt="plasmoran"
              className="plasmoran"
            />
          </div>
        </div>
      </section>
    </>
  );
}
