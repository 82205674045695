import { NavLink } from 'react-router-dom';
import { ProductsTypes } from './productsTypes';

import '../../assets/styles/products/product.scss';

interface ProductProps {
  data: ProductsTypes[];
  textColor?: string;
}

export const Product: React.FC<ProductProps> = ({ data, textColor }) => {
  return (
    <div className="project">
      {data.map((item, index) => (
        <div key={index} className="product_items">
          <NavLink to={item.link} className="project_item_text">
            <h3 className="project_item_title" style={{ color: textColor }}>
              {item.title}
            </h3>
          </NavLink>
          <p className="project_item_text" style={{ color: textColor }}>
            {item.products}
          </p>
          <div className="project_divider"></div>
        </div>
      ))}
    </div>
  );
};
