import React from 'react';
import { AlcoTypes } from '../alcoTypes';

import '../../../assets/styles/prombez/innovation.scss';

interface DataProps {
  data: AlcoTypes[];
}

export const AlcoAdvantages: React.FC<DataProps> = ({ data }) => {
  return (
    <section className="block_container alco_advantages">
      <div className="advantages_block alco_adv">
        {data.map((list, index) => (
          <React.Fragment key={index}>
            <div className="content_block aloframe_content_block">
              <h3 className="innovation_title alco_adv_title">{list.num}</h3>
              <p className="innovation_text alco_adv_text">{list.text}</p>
            </div>
            <div className="divider alco_divider"></div>
          </React.Fragment>
        ))}
      </div>
      <p className="alcoframe_description">
        Алкорамка – бесконтактный экспресс-тестер с уникальной технологией
        моментального выявления алкоголя в выдохе. Устройство устанавливается на
        проходных предприятий и обладает максимальной пропускной способностью.
      </p>
      <img
        src="assets/alco_frame/Fundo_Gift.png"
        alt="img"
        className="alcoframe_img"
      />
      <p className="alco_desc_text">
        Устройство предназначено для оперативного контроля персонала на <br />
        предмет употребления алкоголя на закрытых предприятиях, где <br />
        нетрезвое состояние сотрудников ставит под угрозу безопасность <br />
        человеческой жизни и безопасность производственного процесса в <br />
        целом: предприятия химической и тяжелой промышленности,
        <br /> предприятия нефтедобывающей и нефтеперерабатывающей отрасли,
        <br />
        предприятия пищевой промышленности, транспортные предприятия, <br />
        медицинские учреждения и т.д.
      </p>
    </section>
  );
};
