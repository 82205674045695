import {
  FacilityType,
  MainMedicalEquipmentType,
} from '../../types/medicineTypes';

export const facilityData: FacilityType[] = [
  {
    img: 'assets/medicine/health_care_facility/Rectangle 4430.png',
  },
  {
    img: 'assets/medicine/health_care_facility/2 968305.png',
  },
  {
    img: 'assets/medicine/health_care_facility/3 9.png',
  },
  {
    img: 'assets/medicine/health_care_facility/4_7.png',
  },
];

export const facilityPhase: MainMedicalEquipmentType = {
  title: 'ЭТАПЫ РАБОТЫ:',
  item: [
    'Подбор медицинского оборудования, мебели, аксессуаров',
    'Разработка технологических и инженерных решений',
    'Поставка оборудования со строгим соблюдением сроков',
    'Ввод в эксплуатацию',
    'Гарантия, сервисное обслуживание',
  ],
};
