import { Alсoframe } from './content/alcoframe/Alсoframe';
import { GetCalculation } from './content/getCalculation/GetCalculation';
import { Prombez } from './content/industrialSafety/Prombez';
import { Innovations } from './content/innovations/Innovations';
import { innovationData } from './content/innovations/innovationData';
import { OurAdvantages } from './content/ourAdvantages/OurAdvantages';
import Partners from './content/partners/Partners';
import { PartnersData } from './content/partners/partnersData';
import { Safety } from './content/safety/Safety';

export function PrombezContent() {
  return (
    <>
      <Innovations data={innovationData} />
      <Alсoframe />
      <Safety />
      <OurAdvantages />
      <GetCalculation />
      <Prombez />
      <Partners data={PartnersData} />
    </>
  );
}
