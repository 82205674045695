import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { GetCalculation } from '../prombezPage/content/getCalculation/GetCalculation';
import { AlcoAdvantages } from './content/AlcoAdvantages';
import { AlcoBenefits } from './content/AlcoBenefits';
import { AlcoControl } from './content/AlcoControl';
import { AlcoWork } from './content/AlcoWork';
import { alcoBenefits, dataAlco, dataAlcoConrol } from './dataAlco';

import '../../assets/styles/alcoframe/alcoframe.scss';

export function AlcoframePage() {
  return (
    <>
      <Header />
      <AlcoAdvantages data={dataAlco} />
      <AlcoBenefits data={alcoBenefits} />
      <GetCalculation />
      <AlcoControl data={dataAlcoConrol} />
      <AlcoWork />
      <Footer />
    </>
  );
}
