import '../../assets/styles/contacts/contacts.scss';

export function Details() {
  return (
    <div className="details_container">
      <div className="contacts_info_container">
        <div className="icon_block">
          <img src="assets/about/Vector.svg" alt="ico" className="about_ico" />
          <p className="contact_info_title">Реквизиты:</p>
        </div>
        <div className="details_info_name">
          <p className="contacts_company_name">Наименование:</p>
          <p className="details_info_text">ООО «ИнжиниринКомплектСервис»</p>
        </div>
        <table className="table">
          <tbody>
            <tr>
              <td className="contact_info_title">ИНН:</td>
              <td className="details_info_text">7810421994</td>
            </tr>
            <tr>
              <td className="contact_info_title">КПП:</td>
              <td className="details_info_text">781001001</td>
            </tr>
            <tr>
              <td className="contact_info_title">ОГРН:</td>
              <td className="details_info_text">11577847454434</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="office contacts_info_block">
        <div className="icon_block">
          <img
            src="assets/about/1706286475.svg"
            alt="ico"
            className="office_ico"
          />
          <p className="contact_info_title">Адрес офиса:</p>
        </div>
        <p className="details_info_text office_text">
          192236, г. Санкт-Петербург, ул. Белы Куна, д. 30, лит. А, пом. 23Н,
          оф. 414 <br />
          <br />
          196006, г. Санкт-Петербург, Лиговский пр., д. 266, лит. В, офис 4.4,
          Бизнес центр “Премьер - Лига”
        </p>
      </div>
    </div>
  );
}
