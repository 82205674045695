import { NavLink } from 'react-router-dom';

export function PlasmoranDescription() {
  return (
    <>
      <section className="block_container">
        <div className="wrapper">
          <h2 className="block_title ">
            Плазменно-дуговая технология последнего поколения
          </h2>
          <img
            src="assets/medicine/plasmoran/Rectangle 4420.png"
            alt="img"
            className="plasmoran_img"
          />

          <p className="plasmoran_text block_text ">
            Хирургическая установка «ПЛАЗМОРАН» разработана на базе
            научно-исследовательских центров ПМГМУ им. И.М. Сеченова, РНИМУ им.
            Н.И. Пирогова, Института теоретической и экспериментальной биофизики
            РАН и Центра физико-химической медицины ФМБА. <br />
            <br />
            При создании оборудования учитывался обширный 25-летний клинический
            опыт применения плазменно-дуговых установок предшествующих
            поколений. По своим техническим характеристикам «ПЛАЗМОРАН» не имеет
            аналогов в России и за рубежом.
          </p>

          <div className="plasmo_certificate ">
            <div className="plasomran_distribution">
              <p className="cert_text block_text">
                ООО «ИнжинирингКомплектСервис» является официальным
                дистрибьютором и имеет все необходимые полномочия на ведение
                переговоров, участие в торгах и поставку плазменно-дуговой
                хирургической установки «ПЛАЗМОРАН».
              </p>
            </div>
            <img
              src="assets/medicine/plasmoran/certif.png"
              alt="img"
              className="plasmo_sert_img"
            />
          </div>

          <p className=" block_text  plasmo_description  ">
            С целью определения уровня воздействия установки «Плазморан» на
            патогенную микрофлору, присущую раневым поверхностям человека, а
            также оценки влияния низкотемпературной аргоновой плазмы на
            пролиферативную активность фибробластов человека, начиная с 2019 г.
            проводятся регулярные исследования.
          </p>
          <NavLink
            to="/pdf"
            className={`plasmoran_btn__link block_text plasmo_certificate`}
          >
            <p className=" link blue_link blue_hover plasmo_pdf">
              Отчет о НИИ ФГБВОУВО &quot;ВМА им. С.М. Кирова&quot; 2024 г.
            </p>
            {/* <button
              className={`button blue_btn_hover blue_btn plasmoran_btn`}
              // onClick={openPdfInNewTab}
            >
              Читать
            </button> */}
          </NavLink>
        </div>
      </section>
    </>
  );
}
