import React from 'react';

import AdvantagesList from './AdvantagesList';
import { ourAdvdntagesData } from './ourAdavantagesData';

import '../../../../assets/styles/prombez/ourAdvantages.scss';

export function OurAdvantages() {
  return (
    <section className="advantages_wrapper">
      <div className="block_container adv_cont">
        <div className="wrapper">
          <h2 className="block_title center_title">
            Преимущества работы с нами
          </h2>
          <AdvantagesList data={ourAdvdntagesData} />
        </div>
      </div>
    </section>
  );
}
