import { Footer } from '../../footer/Footer';
import { HeaderTop } from '../../header/HeaderTop';
import { FacilityPhase } from '../healthCareFacility/Facilityphase';
import {
  technicsAdvantages,
  technicsDiagnosis,
  technicsDevelopment,
} from './technicsData';
import '../../../assets/styles/medicine/technics/technics.scss';

export function MedicineTechnicsPage() {
  return (
    <>
      <HeaderTop />
      <section className="block_container technics">
        <div className="wrapper">
          <h3 className="block_title">Техническое обслуживание</h3>
          <p className="divider_text block_text padding_bottom_0">
            ООО «ИнжинирингКомплектСервис» оказывает следующие услуги
            технического обслуживания медицинских изделий, при наличии
            необходимых лицензий:
            <span className="divider_medicine"></span>
          </p>
          <FacilityPhase data={technicsAdvantages} />
          <FacilityPhase data={technicsDiagnosis} />
          <FacilityPhase data={technicsDevelopment} />
          <img
            src="assets/medicine/technics/Rectangle 4448.png"
            alt="img"
            style={{ marginTop: '50px', marginBottom: '47px' }}
            className="car"
          />
        </div>
      </section>
      <Footer />
    </>
  );
}
