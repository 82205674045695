import { AboutTargets } from './AboutTargets';

import '../../../assets/styles/about/aboutStyles.scss';

export function AboutStrategy() {
  return (
    <>
      <div className="wrapper">
        <div className="about_strategy_vision">
          <h2 className="block_title">О нас</h2>
          <p className="block_text">
            Группа компаний «ИнжинирингКомплектСервис» сегодня – это профильные
            организации, специализирующиеся на решении комплексных задач в сфере
            промышленной и гражданской безопасности.
          </p>
        </div>
      </div>
      <AboutTargets />
      <div className="wrapper">
        <div className="about_strategy_vision">
          <h2 className="block_title">Стратегическое видение</h2>
          <p className="block_text strategy_description">
            Достичь лидирующих позиций в качестве надежного партнера. Эффективно
            решать любые задачи и создавать ценности для общества в области
            инновационных технологий.
          </p>
        </div>
      </div>
    </>
  );
}
