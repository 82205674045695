import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
  IoPersonOutline,
  IoMailOutline,
  IoCallOutline,
  IoBriefcaseOutline,
} from 'react-icons/io5';

import ModalWindow from '../utils/modalWindow';
import { EMAILREGEXP, PHONENUMBERREGEXP, REQUIRED } from '../utils/variables';
import { FormDataType } from '../types/iksTypes';
import { onSubmit } from '../utils/formFunctions';
import { SuccessMessage } from '../utils/SuccessMessage';

import '../../assets/styles/footer/footerForm.scss';

const OrderForm: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    resetField,
    formState: { errors },
  } = useForm<FormDataType>({
    defaultValues: {
      name: '',
      email: '',
      message: '',
      phone: '',
      company: '',
      formId: '',
    },
  });

  const formId = 'footer_contact_form';

  const currentPath = location.pathname;
  let currentButtonColor = 'red_btn_hover';
  if (currentPath.includes('/medicine')) {
    currentButtonColor = 'blue_btn blue_btn_hover';
  }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const resetForm = () => {
    resetField('name');
    resetField('email');
    resetField('message');
    resetField('company');
    resetField('phone');
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    console.log('modal window is open');
  };

  const handleFormSubmit = () => {
    handleSubmit((formData) =>
      onSubmit(formData, resetForm, handleOpenModal, formId)
    )();
  };

  return (
    <form
      id="footer_contact_form"
      name="form"
      onSubmit={handleSubmit(handleFormSubmit)}
      className="footer_form"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h3 className="form_title">Решим вашу задачу</h3>
      <div className="input_container">
        <div className="input_container_block" style={{}}>
          <div className="input_block">
            <label className="label">Меня зовут</label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Ваше имя"
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IoPersonOutline />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  {...register('name', {
                    required: 'Это поле обязательно для заполнения',
                  })}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  sx={{
                    width: '291px',
                    marginRight: '16px',
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'rgba(137, 9, 7, 1)',
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="input_block">
            <label className="label">Моя компания</label>
            <Controller
              name="company"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Ваша компания"
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IoBriefcaseOutline />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  {...register('company', {
                    required: REQUIRED,
                  })}
                  error={!!errors.company}
                  helperText={errors.company?.message}
                  sx={{
                    width: '291px',
                    marginRight: '16px',
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'rgba(137, 9, 7, 1)',
                    },
                  }}
                />
              )}
            />
          </div>
        </div>
        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="input_block input_block2 ">
            <label className="label">Телефон</label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Ваш номер телефона"
                  {...field}
                  onFocus={handleFocus}
                  InputProps={{
                    // onBlur: () => {
                    //   field.onBlur();
                    //   handleBlur();
                    // },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IoCallOutline />
                        {isFocused && '+7'}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  {...register('phone', {
                    required: REQUIRED,
                    pattern: {
                      value: PHONENUMBERREGEXP,
                      message:
                        'Введите номер телефона в формате "+75553523535"',
                    },
                  })}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  sx={{
                    width: '291px',
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'rgba(137, 9, 7, 1)',
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="input_block input_block2">
            <label className="label">E-mail</label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Ваш email"
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IoMailOutline />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  {...register('email', {
                    required: REQUIRED,
                    pattern: {
                      value: EMAILREGEXP,
                      message: 'Введите корректный email адрес',
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  sx={{
                    width: '291px',
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'rgba(137, 9, 7, 1)',
                    },
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <Controller
        name="message"
        control={control}
        render={({ field }) => (
          <TextField
            id="outlined-basic"
            label="Пожалуйста, опишите направление Вашего Бизнеса."
            {...field}
            variant="outlined"
            {...register('message', {
              required: REQUIRED,
            })}
            error={!!errors.message}
            helperText={errors.message?.message}
            multiline
            inputProps={{
              style: { minHeight: '132px', overflowWrap: 'break-word' },
            }}
            sx={{
              maxWidth: '840px',
              minWidth: '600px',
              minHeight: '142px',
              borderRadius: '10px',
              boxShadow: '0px 2px 35px 0px rgba(0, 0, 0, 0.08)',
              '& fieldset': { border: 'none' },
              '& label.Mui-focused': {
                color: 'currentColor',
              },
            }}
          />
        )}
      />
      <button className={`button_footer button_link ${currentButtonColor}`}>
        Отправить
      </button>
      <ModalWindow isOpen={isModalOpen} onClose={handleCloseModal}>
        <SuccessMessage handleCloseModal={handleCloseModal} />
      </ModalWindow>
    </form>
  );
};

export default OrderForm;
