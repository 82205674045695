import { MainTypes } from '../prombezPage/prombezTypes';

export const headerBottomData: MainTypes[] = [
  {
    title: 'Инновационное оборудование для безопасности и здоровья',
    desc: 'Мы предоставляем вам наилучшие решения для обеспечения безопасности и здоровья ваших сотрудников и клиентов',
  },
  {
    title: 'Инновационные решения для промышленной безопасности ',
    desc: 'Обеспечение безопасной работы компаний по всей России',
  },
  {
    title:
      'АЛКОРАМКА - инновационное решение для защиты персонала и Вашего бизнеса',
    desc: 'Бесконтактный экспресс-тестер с уникальной технологией моментального выявления алкоголя в выдохе',
  },
  {
    title: 'Комплексные решения в сфере здравоохранения',
    desc: 'Обеспечение безопасной работы компаний по всей России',
  },
  {
    title: 'Хирургическая установка «ПЛАЗМОРАН»',
    desc: 'новейшая разработка российских ученых в области плазменно-дуговых технологий',
  },
];
