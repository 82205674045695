import { Footer } from '../../footer/Footer';
import { HeaderTop } from '../../header/HeaderTop';
import { HealthCareFacility } from './HealthCareFacility';

export function FacilityPage() {
  return (
    <>
      <HeaderTop />
      <HealthCareFacility />
      <Footer />
    </>
  );
}
