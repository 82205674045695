import { Footer } from '../../footer/Footer';
import { Header } from '../../header/Header';
import { GetCalculation } from '../../prombezPage/content/getCalculation/GetCalculation';
import { HowWorksPlasmoran } from './HowWorksPlasmoran';
import { PlasmoranDescription } from './PlasmoTehnology';
import { PlasmoranAdvantages } from './PlasmoranAdvantages';
import { PlasmoranEconomics } from './PlasmoranEconomics';
import { PlasmoranMilitary } from './PlasmoranMilitary';
import { PlasmoranScope } from './PlasmoranScope';
import { plasmoAdvantagesData, plasmoScopeData } from './plasmoranData';

export function MedicinePlasmoranPage() {
  return (
    <>
      <Header />
      {/* <object
        data="assets/Отчет_о_научно_исследовательской_работе_ВМА_им_Кирова.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
      </object> */}

      <PlasmoranAdvantages data={plasmoAdvantagesData} />
      <PlasmoranDescription />
      <HowWorksPlasmoran />
      <GetCalculation />
      <PlasmoranScope data={plasmoScopeData} />
      <PlasmoranMilitary />
      <PlasmoranEconomics />
      <Footer />
    </>
  );
}
