import { Footer } from '../footer/Footer';
import { HeaderTop } from '../header/HeaderTop';
import { Contact } from './Contact';
import { dataContacts } from './dataContacts';
import { Details } from './Details';
import { Map } from './Map';
import '../../assets/styles/contacts/contacts.scss';

export function ContactsPage() {
  return (
    <>
      <HeaderTop />
      <section className="block_container contact_container">
        <h2 className="block_title padding_bottom_0">Контактные данные</h2>
        <Contact data={dataContacts} />
        <Map />
        <Details />
        <Footer />
      </section>
    </>
  );
}
