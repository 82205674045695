import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { PrombezContent } from './PrombezContent';

export function PrombezPage() {
  return (
    <>
      <Header />
      <PrombezContent />
      <Footer />
    </>
  );
}
