import { ListFunction } from '../../utils/ListFunction';
import { plasmoMilitaryData_1, plasmoMilitaryData_2 } from './plasmoranData';

export function PlasmoranMilitary() {
  return (
    <>
      <div className="block_container">
        <div className="wrapper">
          <h2 className=" block_title">
            Военно-полевая хирургия и реабилитация военнослужащих
          </h2>
          <div className="military_desc">
            <div className="military_block media_reverse">
              <div className="military_block_text">
                <p className="block_text military_text">
                  Среди основных задач военной медицины является оказание
                  своевременной квалифицированной помощи на местах боевых
                  действий, а также подготовка раненных военных к эвакуации и
                  предотвращение развития осложнений во время транспортировки
                  солдат в специализированные госпитали.
                </p>
                <br />
                <br />
                <p className="block_text military_text">
                  Применение установки «Плазморан» в пунктах оказания первой
                  врачебной и квалифицированной помощи позволит существенно
                  повысить качество оказываемой медицинской помощи, а также
                  увеличить ее объем и доступность в сжатые сроки.
                </p>
              </div>
              <img
                src="assets/medicine/plasmoran/Rectangle 4423.png"
                alt="img"
                className="military_img"
              />
            </div>
            <div className="military_block ">
              <img
                src="assets/medicine/plasmoran/Rectangle 4424.png"
                alt="img"
                className="military_img"
              />
              <p className="block_text military_text military_text_doctor">
                Установка «ПЛАЗМОРАН» не требует специальной квалификации у
                медперсонала, обладает повышенной мобильностью, экономична в
                использовании.
              </p>
            </div>
            <div className="military_list_block">
              <div className="military_list_1">
                <ListFunction data={plasmoMilitaryData_1} />
              </div>
              <div className="military_list_2">
                <ListFunction data={plasmoMilitaryData_2} />
                <img
                  src="assets/medicine/plasmoran/Rectangle 4429.png"
                  alt="img"
                  className="military_img"
                />
              </div>
            </div>
            <p className="plasmoran_text block_text">
              Таким образом, внедрение данного оборудования в практику
              военно-полевой хирургии и реабилитация военнослужащих позволит
              повысить качество оказываемой медицинской помощи, ускорить процесс
              эвакуации и реабилитации раненых солдат, а также высвободить
              медицинский персонал для оказания большего объема неотложной и
              срочной медицинской помощи.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
