import { AlcoControlTypes } from '../alcoTypes';

interface DataProps {
  data: AlcoControlTypes[];
}

export const AlcoControl: React.FC<DataProps> = ({ data }) => {
  return (
    <section className="block_container alco_control">
      <div className="wrapper">
        <h2 className="block_title alco_control_title ">
          ПОЧЕМУ КОНТРОЛЬ АЛКОГОЛЬНОГО ОПЬЯНЕНИЯ НА РАБОЧЕМ МЕСТЕ – ЭТО ВАЖНО?
        </h2>
        <div className="alco_control_container">
          <div className="alco_control_list">
            {data.map((list, index) => (
              <div key={index} className="alco_control_item">
                <h3 className="alco_control_list_title">{list.title}</h3>
                <p className="alco_control_text">{list.text}</p>
              </div>
            ))}
          </div>
          <img
            src="assets/alco_frame/image_34.png"
            className="alco_control_img"
            alt="img"
          />
        </div>
      </div>
    </section>
  );
};
