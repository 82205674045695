import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Element as ScrollElement } from 'react-scroll';

import { PartnersTypes } from '../../../prombezPage/prombezTypes';

import '../../../../assets/styles/prombez/partners.scss';

interface DataProps {
  data: PartnersTypes[];
}

const Partners: React.FC<DataProps> = ({ data }) => {
  const settings = {
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <section className="block_container">
      <ScrollElement name="partners">
        <div className="wrapper">
          <h2 className=" block_title center_title">
            Мы доверяем только проверенным партнерам
          </h2>
          <Slider {...settings} className="partners_list">
            {data.map((item, index) => (
              <div key={index}>
                <img src={item.img} alt={item.img} className="" />
              </div>
            ))}
          </Slider>
        </div>
      </ScrollElement>
    </section>
  );
};

export default Partners;
