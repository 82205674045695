import { NavLink } from 'react-router-dom';

import { Link, animateScroll as scroll } from 'react-scroll';

import { RouteTypes } from '../header/routeTypes';

import '../../assets/styles/footer/footer.scss';

export function FooterInfo() {
  const routeBottomStyle: RouteTypes = {
    '/medicine_main': 'footer_med_info_bgc',
  };

  const currentBottomStyle = routeBottomStyle[location.pathname] || '';

  const currentPath = location.pathname;
  let currentColorPoundsSrc = 'assets/prombez_page/footerPounds.svg';
  if (currentPath.includes('/medicine')) {
    currentColorPoundsSrc = 'assets/icons/blueRounds.png';
  }

  return (
    <div className={`footer_info_bgc ${currentBottomStyle}`}>
      <div className="footer_info">
        <div className="footer_info_data mobile__display_none">
          <NavLink className="footer_link" to="/about">
            <h3 className="footer_info_title">О нас</h3>
          </NavLink>
          <Link
            to="partners"
            className="info_link"
            smooth={true}
            duration={500}
            // onClick={scroll.scrollToTop}
          >
            Партнеры
          </Link>
          <Link
            to="certificates"
            className="info_link"
            smooth={true}
            duration={500}
          >
            Лицензии и сертификаты
          </Link>
        </div>
        <div className="footer_info_data mobile__display_none">
          <NavLink className="footer_link" to="/products">
            <h3 className="footer_info_title">Продукция</h3>
          </NavLink>
          <NavLink className="info_link" to="/alcoframe">
            Алкорамки
          </NavLink>
          <NavLink className="info_link" to="/video">
            Системы видеонаблюдения
          </NavLink>
          <NavLink className="info_link" to="/turnstiles">
            Системы контроля управления доступом
          </NavLink>
          <NavLink className="info_link" to="/it">
            IT-оборудование
          </NavLink>
        </div>
        <div className="footer_info_data info_contacts">
          <NavLink className="footer_link" to="/contacts">
            <h3 className="footer_info_title">Контакты</h3>
          </NavLink>
          <div className="address">
            <img
              src="assets/prombez_page/geo_icon.svg"
              alt="geo"
              className="footer_ico"
            />
            <p className="info_link address_text">
              192236, г. Санкт-Петербург, ул. Белы Куна, д. 30, лит. А, пом.
              23Н, оф. 414
            </p>
          </div>
        </div>
        <div className="footer_info_data data_address">
          <div className="address">
            <img
              src="assets/prombez_page/phone_footer_icon.svg"
              alt="geo"
              className="footer_ico"
            />
            <p className="info_link phone_text">
              тел:&nbsp;
              <a href="tel: +78124267978" className="info_link footer_phone">
                +7 (812) 426-79-78
              </a>
            </p>
          </div>
          <div className="address">
            <img
              src="assets/prombez_page/mail_icon.svg"
              alt="geo"
              className="footer_ico"
            />
            <p className="info_link phone_text">
              e-mail:&nbsp;
              <a href="mailto:info@rosprotect.ru" className="info_link">
                info@rosprotect.ru
              </a>
            </p>
          </div>
        </div>
        <div className="footer_info_clock address clock">
          <div className="footer_info_data data_address clock_data">
            <img
              src="assets/prombez_page/clock_icon.svg"
              alt="clock"
              className="footer_ico "
            />
          </div>
          <div className="footer_info_data info_clock">
            <img src={currentColorPoundsSrc} alt="pounds" className="" />
            <p className="info_link phone_text">с 10:00 до 18:00</p>
          </div>
        </div>
      </div>
    </div>
  );
}
