export const AboutTargets = () => {
  return (
    <>
      <div className="about_card">
        <img
          src="assets/about_page/business-team-meeting-strategy-marketing-cafe-concept 1.png"
          alt="img"
          className="about_img"
        />
        <div className="about_content">
          <h2 className="block_title">Наша миссия и цели</h2>
          <article className="about_target_text">
            Основная миссия – своевременное выявление проблем у заказчиков,
            разработка и внедрение высокоэффективных инновационных технологий и
            установок, направленных на оптимизацию производственных показателей
            предприятия, улучшение условия труда и эффективности работы
            сотрудников, а также снижение риска несчастных случаев и финансовых
            потерь. <br />
            <br />
            Цель нашей деятельности: обеспечение потребностей Заказчика в
            современном оборудовании путем разработки и внедрения
            высокоэффективных инновационных технологий и установок российского
            производства.
          </article>
        </div>
      </div>
    </>
  );
};
