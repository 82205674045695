import { Footer } from '../footer/Footer';
import { HeaderTop } from '../header/HeaderTop';
import { Turnstiles } from './Turnstiles';

export function TurnstilesPage() {
  return (
    <>
      <div className="block_container ">
        <div className="wrapper turnstiles_wrapper">
          <HeaderTop />
          <h2 className="block_title padding_bottom_0">Турникеты</h2>
          <Turnstiles />
        </div>
      </div>
      <Footer />
    </>
  );
}
