import { useLocation } from 'react-router-dom';
import { HeaderTop } from './HeaderTop';
import { RouteTypes } from './routeTypes';
import { HeaderBottom } from './HeaderBottom';
import { headerBottomData } from './headerBottomData';
import '../../assets/styles/header/headerMainStyles.scss';
import { useEffect, useState } from 'react';

export function Header() {
  const location = useLocation();
  const [windowSize, setWindowSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const routeStyles: RouteTypes = {
    '/': 'header_main_bgi',
    '/alcoframe': 'header_alcoframe_bgi',
    '/prombez': 'header_prombez_bgi',
    '/medicine_main': 'medicine_main_bgi',
    '/medicine_plasmoran': 'medicine_plasmoran_bgi',
  };

  const currentRouteStyles = routeStyles[location.pathname] || '';
  const isRouteWithVideoStyles = currentRouteStyles === 'header_alcoframe_bgi';
  const isSpecialRoute = location.pathname === '/alcoframe';

  return (
    <header
      className={`header ${currentRouteStyles}${
        isSpecialRoute ? ' special_background' : ''
      }`}
      style={{ width: windowSize.width, height: windowSize.height }}
    >
      {isRouteWithVideoStyles ? (
        <div className="header_alcoframe_bgi">
          <div className="background_video">
            <video autoPlay muted loop>
              <source src="assets/alco_frame/alcoframe.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="content_overlay">
            <HeaderTop />
            <HeaderBottom items={headerBottomData} />
          </div>
        </div>
      ) : (
        <>
          <HeaderTop />
          <HeaderBottom items={headerBottomData} />
        </>
      )}
    </header>
  );
}
