import InnovationTable from './InnovationsTable';

import '../../../../assets/styles/prombez/innovation.scss';
import { MainTypes } from '../../prombezTypes';

export type InnovationType = Array<MainTypes>;

export function Innovations({ data }: { data: InnovationType }) {
  const currentPath = location.pathname;
  let advantagesBg = '';

  if (currentPath.includes('/medicine')) {
    advantagesBg = 'blue_advantages';
  }
  return (
    <section className="block_container">
      <div className="wrapper innovations_wrapper">
        <h2 className="block_title center_title">
          Внедряем инновации в безопасность на новом уровне
        </h2>
        <article className="block_description_text block_text">
          Группа компаний «ИКС» сегодня – это профильные организации,
          специализирующиеся на решении комплексных задач в сфере промышленной и
          гражданской безопасности
        </article>
      </div>
      <div className={`advantages ${advantagesBg}`}>
        <InnovationTable data={data} />
      </div>
    </section>
  );
}
