import { ActivityTypes } from './activityTypes';

export const dataActivity: ActivityTypes[] = [
  {
    title: 'Благотворительность',
    text: 'Передача средств индивидуальной защиты для нужд Воскресенского Новодевичьего монастыря',
    img: 'assets/main_page/charity.png',
    year: '2023',
  },
  {
    title: 'Поставка для нужд СВО',
    text: 'Поставки защитного обмундирования и тактического оборудования для поддержки СВО',
    img: 'assets/main_page/svo.png',
    year: '2023',
  },
  {
    title: 'Работа с администрацией ',
    text: 'Участие в подготовке визитов главы Санкт-Петербурга в дружественные страны',
    img: '/assets/main_page/gov.png',
    year: '2023',
  },
];
