import { Footer } from '../footer/Footer';
import { HeaderTop } from '../header/HeaderTop';
import { Product } from './Product';
import { dataMedicines, dataProducts } from './dataProducts';

import '../../assets/styles/products/product.scss';

export function ProductsPage() {
  return (
    <>
      <div className="block_container project_container">
        <HeaderTop />
        <div className="wrapper">
          <h2 className="block_title ">
            Портфель решений — совокупность производимых компанией товаров и
            услуг по направлениям:
          </h2>
          <h3 className="products_title_color">
            Комплексные решения в сфере промышленной и гражданской безопасности
          </h3>
          <Product data={dataProducts} />
          <h3 className="products_title_color" style={{ color: '#2C689E' }}>
            Комплексные решения в сфере здравоохранения
          </h3>
          <Product data={dataMedicines} textColor="#2C689E" />
        </div>
        <Footer />
      </div>
    </>
  );
}
