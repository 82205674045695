import AboutSlider from './AboutSlider';
import { aboutUsData } from './dataAbout';

import { Element as ScrollElement } from 'react-scroll';

export function AboutTestimonies() {
  return (
    <ScrollElement name="certificates">
      <div className="block_container ">
        <div className="wrapper">
          <h2 className="block_title">Свидетельства и благодарности</h2>
          <AboutSlider slides={aboutUsData} />
        </div>
      </div>
    </ScrollElement>
  );
}
