import { PartnersTypes } from '../../../prombezPage/prombezTypes';

export const PartnersData: PartnersTypes[] = [
  {
    img: 'assets/prombez_page/partner6.svg',
  },
  {
    img: 'assets/prombez_page/partner1.svg',
  },
  {
    img: 'assets/prombez_page/partner2.svg',
  },
  {
    img: 'assets/prombez_page/partner3.svg',
  },
  {
    img: 'assets/prombez_page/partner4.svg',
  },
  {
    img: 'assets/prombez_page/partner5.svg',
  },
];
