import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';

import { PHONENUMBERREGEXP, REQUIRED } from '../utils/variables';
import { onSubmit } from '../utils/formFunctions';
import { FormDataType } from '../types/iksTypes';

import '../../assets/styles/header/headerForm.scss';

interface OrderFormProps {
  className?: string;
  onFormSubmitAction?: () => void;
}

export const HeaderForm: React.FC<OrderFormProps> = ({
  className,
  onFormSubmitAction,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    resetField,
    formState: { errors },
  } = useForm<FormDataType>({
    defaultValues: {
      name: '',
      phone: '',
      company: '',
      formId: '',
    },
  });

  const formId = 'header_form';

  const resetForm = () => {
    resetField('name');
    resetField('company');
    resetField('phone');
  };

  const handleFormSubmit = () => {
    if (onFormSubmitAction) {
      handleSubmit((formData) =>
        onSubmit(formData, resetForm, onFormSubmitAction, formId)
      )();
      console.log('Form submitted on mobile');
    }
  };

  const handleFocus = () => setIsFocused(true);

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className=" header_form"
      id="header_form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <h3 className="form_title header_form_title">Заказать обратный звонок</h3>
      <p className="header_form_text">Оставьте номер и мы Вам перезвоним</p>
      <div className="input_container">
        <div
          className="input_container_block header_form__input_block"
          style={{}}
        >
          <div className="input_block ">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Имя*"
                  {...field}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  {...register('name', {
                    required: 'Это поле обязательно для заполнения',
                  })}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  sx={{
                    marginRight: '16px',
                    '&:hover .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgb(137, 9, 7)',
                      },
                    },
                    '&.Mui-focused .MuiTextField-root': {
                      '& fieldset': {
                        borderColor: 'rgb(137, 9, 7)',
                      },
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="input_block">
            <Controller
              name="company"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Компания*"
                  {...field}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  {...register('company', {
                    required: REQUIRED,
                  })}
                  error={!!errors.company}
                  helperText={errors.company?.message}
                  sx={{
                    marginRight: '16px',
                    '&:hover .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgb(137, 9, 7)',
                      },
                    },
                    '&.Mui-focused .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgb(137, 9, 7)',
                      },
                    },
                  }}
                />
              )}
            />
          </div>

          <div className="input_block ">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder={isFocused ? '' : '+7___ • ___ • __ • __'}
                  {...field}
                  onFocus={handleFocus}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocused && '+7'}
                      </InputAdornment>
                    ),
                  }}
                  {...register('phone', {
                    required: REQUIRED,
                    pattern: {
                      value: PHONENUMBERREGEXP,
                      message:
                        'Введите номер телефона в формате "+75553523535"',
                    },
                  })}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  sx={{
                    marginRight: '16px',
                    '&:hover .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgb(137, 9, 7)',
                      },
                    },
                    '&.Mui-focused .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgb(137, 9, 7)',
                      },
                    },
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <button className=" button_footer button_link header_form_button">
        Узнать точную стоимость
      </button>
      <p className="header_form_additional_info">
        *Согласен с правилами обработки персональной информации
      </p>
      <div />
    </form>
  );
};
