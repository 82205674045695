import { Footer } from '../footer/Footer';
import { HeaderTop } from '../header/HeaderTop';
import { FacilityPhase } from '../medicine/healthCareFacility/Facilityphase';
import { videoData, videoData2 } from './dataVideo';

export function VideoPage() {
  return (
    <>
      <HeaderTop />
      <section className="block_container">
        <div className="wrapper hardware">
          <h3 className="block_title ">Видеонаблюдение</h3>
          <p className="divider_text block_text padding_bottom_0 ">
            Компания предлагает эффективные и надежные комплексные решения по
            интеграции систем охранного видеонаблюдения, которые будет
            гарантированно выполнять возложенные на нее функции на протяжении
            всего срока службы. <br />
            <br /> При подборе оборудования и проектировании систем используются
            только проверенные технологии и принципы, помимо этого для
            Заказчиков доступен широкий спектр инжиниринговых и эксплуатационных
            услуг.
            <span className="divider_medicine bgc_red"></span>
          </p>
          <img
            src="assets/video/beward.png"
            alt="logo"
            className="turntiles_logo"
          />
          <p className=" block_text padding_bottom_0 ">
            Научно-производственное предприятие «Бeвард» (BEWARD) – ведущая
            российская компания, основанная в 2004 году как разработчик и
            производитель IP-камер. За годы работы «Бeвард» сумел применить и
            адаптировать в России весь накопленный мировой опыт в области
            IP-решений и максимально снизить стоимость таких решений. В
            настоящее время BEWARD представляет собой группу компаний,
            объединенных единым замыслом разработки и внедрения собственных
            профессиональных решений обеспечения безопасности на объектах любой
            сложности.
          </p>
          <div className="rows">
            <FacilityPhase data={videoData} />
            <FacilityPhase data={videoData2} />
          </div>
          <img
            src="assets/video/cameras.png"
            alt="cameras"
            className="medicine_item_img video_img
            "
          />
        </div>
      </section>
      <Footer />
    </>
  );
}
