import { ContactsTypes } from './Contact';

export const dataContacts: ContactsTypes[] = [
  {
    img: 'assets/about/61547.png',
    ico: 'assets/about/phone-icon.png',
    title: 'Позвоните нам!',
    text: 'Есть вопросы, мы поможем!',
    info: '+7 (812) 426-79-78',
  },
  {
    img: 'assets/about/2839833.png',
    ico: 'assets/about/mail-icon.svg',
    title: 'Напишите нам',
    text: 'Идеи, предложения? Мы открыты для любых вопросов!',
    info: 'info@rosprotect.ru',
  },
  {
    img: 'assets/about/2968304.png',
    ico: 'assets/about/bubble-icon.svg',
    title: 'Обратная связь',
    text: 'Поделитесь мнением о нашей работе и помогите нам стать лучше!',
    info: 'Оставить отзыв',
  },
];
