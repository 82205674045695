import {
  FacilityType,
  HardwareItemsType,
  ImgsTypes,
  MainMedicalEquipmentType,
} from '../../types/medicineTypes';

export const indoorСlothes: HardwareItemsType = {
  title: '',
  paragraph_1:
    'Высокое качество поставляемой продукции, инновационные решения, применяемые в ее производстве, забота о клиенте и чуткое реагирование на его запросы – основные принципы, которыми мы руководствуемся при выборе производителей.',
  imgs: [
    'assets/medicine/clothes/Rectangle 4449.png',
    'assets/medicine/clothes/Rectangle 4450.png',
  ],
  paragraph_2:
    'Одежда и обувь для медицины от ведущих производителей – для врачей, для младшего и среднего медицинского персонала, сезонные костюмы для работников скорой помощи.',
};

export const outdoorСlothes: HardwareItemsType = {
  title: '',
  paragraph_1:
    'В портфеле присутствует продукция, получившая заключение Минпромторга России о подтверждении производства промышленной продукции на территории РФ.',
  imgs: [
    'assets/medicine/clothes/Rectangle 4451.png',
    'assets/medicine/clothes/Rectangle 4452.png',
  ],
  paragraph_2:
    'Большая часть из предлагаемого ассортимента производится на фабриках, находящихся в России, благодаря этому есть возможность строго контролировать качество продукции, сам процесс производства от поступления материалов до упаковки и отгрузки.',
};

export const personalProtect: MainMedicalEquipmentType = {
  title:
    'Отдельную категорию СИЗ составляют медицинские средства индивидуальной защиты (МСИЗ), в первую очередь предназначенные для:',
  item: [
    'Медицинских работников при проведении медицинских процедур или во времянахождения в медицинской организации для предотвращения перекрёстного заражениям организмами, загрязнения физиологическими жидкостями и твёрдыми частицами между пациентом и персоналом, при контактах с инфекционно-опасными пациентами, при выполнении дезинфекционных работ',
    'Пациентов при проведении медицинских процедур или во время нахождения в медицинской организации;',
    'Посетителей при нахождении в лечебных учреждениях',
  ],
};

export const personalProtectImgs: ImgsTypes = [
  'assets/medicine/clothes/Rectangle 4453.png',
  'assets/medicine/clothes/Rectangle 4454.png',
  'assets/medicine/clothes/Rectangle 4455.png',
  'assets/medicine/clothes/Rectangle 4456.png',
];

export const personalProtectItems: MainMedicalEquipmentType = {
  title: '',
  item: [
    'защитные комбинезоны, халаты, фартуки',
    'бахилы, нарукавники, шапочки, шлемы',
    'маски, респираторы',
    'перчатки, очки, экраны, щитки',
  ],
};

export const clothesCaruselData: FacilityType[] = [
  {
    img: 'assets/medicine/clothes/Rectangle 4460.png',
  },
  {
    img: 'assets/medicine/clothes/Rectangle 4461.png',
  },
  {
    img: 'assets/medicine/clothes/Rectangle 4462.png',
  },
  {
    img: 'assets/medicine/clothes/Rectangle 4463.png',
  },
  {
    img: 'assets/medicine/clothes/37 1.png',
  },
  {
    img: 'assets/medicine/clothes/38 1.png',
  },
  {
    img: 'assets/medicine/clothes/39 1.png',
  },
];
