import {
  HardwareItemsType,
  MainMedicalEquipmentType,
} from '../../types/medicineTypes';

export const ultraSound: HardwareItemsType = {
  title: 'Ультра-звуковая диагностика',

  paragraph_1:
    'В портфеле "Ультразвуковая диагностика" представлена продукция известных мировых брендов: BK medical, General Electric (GE Healthcare), Mcube Technology и PENTAX Medical.' +
    'Ультразвуковые системы для общей и узкоспециализированной диагностики, а также всех видов малоинвазивных вмешательств.',
  imgs: [
    'assets/medicine/medicine_hardware/4431.png',
    'assets/medicine/medicine_hardware/4432.png',
    'assets/medicine/medicine_hardware/4433.png',
  ],
  paragraph_2: '',
};

export const MRI: HardwareItemsType = {
  title: 'Магнитно-резонансные томографы(МРТ)',
  paragraph_1:
    'Аппарат МРТ – это высокотехнологичное медицинское оборудование, используемое в отделениях диагностики для получения изображений разных частей организма: мягких тканей, суставов, хрящей, межпозвоночных дисков и пр. При помощи магнитно-резонансного томографа бренда GE Healthcare можно выявить значительное число заболеваний, в том числе на ранних стадиях их развития.',
  imgs: [
    'assets/medicine/medicine_hardware/4434.png',
    'assets/medicine/medicine_hardware/4435.png',
  ],
  paragraph_2:
    'Специальнаяпрограмма от GE Healthcare направлена на модернизацию существующих МРТ-системдо МРТ новейшего поколения. Новые приложения и аппаратное обеспечениепоследнего поколения программы SIGNA Lift создадут новую систему вокругимеющегося магнита, заменив все электронные компоненты, градиенты и рабочуюстанцию, установив обновление в виде программного пакета SIGNA Works.',
};

export const CT: HardwareItemsType = {
  title: 'Компьютерные томографы (аппараты КТ)',
  paragraph_1:
    'Компьютерный томограф – это современное медицинское оборудование, применяемое для диагностики внутренних органов и систем методом многослойного сканирования за счет рентгеновских лучей.',
  imgs: [
    'assets/medicine/medicine_hardware/4436.png',
    'assets/medicine/medicine_hardware/4437.png',
  ],
  paragraph_2:
    'Компьютерные томографы GE Healthcare, созданные на базе надежных и испытанных технологий ,сочетают в себе самые современные клинические возможности.',
};

export const xRay: HardwareItemsType = {
  title: 'Рентгеновские аппараты',
  paragraph_1:
    'Рентгеновский аппарат - это современное оборудование отделения диагностики, облегчающее работу специалистов и обеспечивающее пациенту комфортные условия в ходе исследования. В нашем портфеле представлены палатные рентгеновские аппараты, цифровые рентгеновские аппараты известных зарубежных брендов Faxitron и GEHealthcare.',
  imgs: [
    'assets/medicine/medicine_hardware/4438.png',
    'assets/medicine/medicine_hardware/4439.png',
  ],
  paragraph_2:
    'Впечатляющеекачество изображения предлагаемых систем способствует точной постановкедиагноза, уверенному принятию решений, достижению удовлетворенности пациентов иболее частым направлениям.',
};

export const ALV: HardwareItemsType = {
  title: 'Аппараты искусственной вентиляциилегких (ИВЛ)',
  paragraph_1:
    'Аппарат искусственной вентиляции легких (ИВЛ) - это высокотехнологичное оборудование, необходимое для оснащения палат и отделений анестезиологии и реанимации, отделений реанимации новорожденных. В нашем портфеле представлены аппараты искусственной вентиляции известных мировых брендов: Lowenstein Medical, Dräger Medical, Medtronic,General Electric (GE Healthcare).',
  imgs: [
    'assets/medicine/medicine_hardware/4440.png',
    'assets/medicine/medicine_hardware/4441.png',
    'assets/medicine/medicine_hardware/4442.png',
    'assets/medicine/medicine_hardware/4445.png',
  ],
  paragraph_2:
    'Конструктивно аппараты ИВЛ состоят из устройства, осуществляющего транспортировку воздушной смеси, электронной системы управления работой оборудования и набора датчиков, с помощью которых производится мониторинг состояния пациента. Воздушная смесь подается в дыхательные органы под постоянным или повышающимся на вдохе давлением. Смена фаз дыхания происходит в соответствии с установленными параметрами.',
};

export const furniture: HardwareItemsType = {
  title: 'Мебель для медицинских учреждений',
  paragraph_1:
    'Сертифицированная медицинская мебель и оборудование Merivaara, Ropimex, Rudolf Riester, TECNO-GAZ- это универсальное решение задач оснащения палат пациента, лечебных кабинетов, санаториев и приемных кабинетов профильных специалистов. Мебель и оборудование имеет высокую степень комфорта, отличается практичностью, надежностью и эстетичным внешним видом.',
  imgs: [
    'assets/medicine/medicine_hardware/4443.png',
    'assets/medicine/medicine_hardware/4444.png',
    'assets/medicine/medicine_hardware/4446.png',
    'assets/medicine/medicine_hardware/4447.png',
  ],
  paragraph_2: '',
};

export const hardwareCategories: MainMedicalEquipmentType = {
  title: 'Медицинское оборудование представлено в следующих категориях: ',
  item: [
    'медицинские кровати и комплектующие;',
    'общебольничное оборудование (прикроватные тумбочки и пр.);светильники (гибкие стоматологические осветители, бестеневые светодиодные светильники и пр.);',
    'системы визуальной защиты и медицинские перегородки(телескопические штанги, ширмы, системы для душа, настенные держатели, складные перегородки и пр.);',
    'широкий диапазон сопутствующей продукции для современного больничного ухода (медицинские тележки, каталки, шкафы, тележки для мытья больных и пр.).',
  ],
};
