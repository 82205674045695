import React, { useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { FacilityType } from '../../types/medicineTypes';
import '../../../assets/styles/mainPage/sliderStyles.scss';
import '../../../assets/styles/medicine/facility/facilityStyles.scss';

SwiperCore.use([Navigation, Pagination]);

interface SliderProps {
  slides: FacilityType[];
}

const AboutSlider: React.FC<SliderProps> = ({ slides }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const swiperRef = useRef(null);
  const [isPrevButtonActive, setPrevButtonActive] = useState(false);
  const [isNextButtonActive, setNextButtonActive] = useState(true);

  const handleSlideChange = (swiper: SwiperCore) => {
    setPrevButtonActive(!swiper.isBeginning);
    setNextButtonActive(!swiper.isEnd);
  };

  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      disableOnInteraction: false,
    },
    loop: true,
    slidesPerView: 3,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },

      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1320: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    speed: 1000,
    navigation: {
      nextEl: '.btn_next',
      prevEl: '.btn_prev',
    },
    on: {
      slideChange: () =>
        handleSlideChange(swiperRef.current || ({} as SwiperCore)),
    },
  };

  const aboutSwiperId = 'aboutSwiperId';

  return (
    <div className="swiper-container about_carousel_container">
      <Swiper id={aboutSwiperId} {...params} onSwiper={setSwiper}>
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="text-container">
            <img src={slide.img} alt="img" className="about_carousel_img " />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="arrows_slider btns_about">
        <button className={`btn_prev `}></button>
        <button className={`btn_next`}></button>
      </div>
    </div>
  );
};

export default AboutSlider;
