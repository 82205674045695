import { HardwareItemsType } from '../../types/medicineTypes';

import '../../../assets/styles/medicine/hardware/hardwareStyles.scss';

interface DataProps {
  data: HardwareItemsType;
}

export const HardwareItemsList: React.FC<DataProps> = ({ data }) => {
  return (
    <div className="equipment_block hardware_items_block">
      <h3 className="hardware_title">{data.title}</h3>

      <p className="block_text">{data.paragraph_1}</p>
      <div className="imgs_container">
        {data.imgs.map((img, index) => (
          <img key={index} src={img} alt="img" className="medicine_item_img" />
        ))}
      </div>
      <p className="block_text padding_bottom_0">{data.paragraph_2}</p>
    </div>
  );
};
