import { MainMedicalEquipmentType } from "../types/medicineTypes";

export const videoData: MainMedicalEquipmentType = {
    title: ' ',
    item: [
      'IP камеры',
      'Объективы',
      'IP видеосерверы',
      // 'IP домофоны',
      // 'Сетевое оборудование',
      // 'Программное обеспечение',
    ],
  };

  export const videoData2: MainMedicalEquipmentType = {
    title: ' ',
    item: [

      'IP домофоны',
      'Сетевое оборудование',
      'Программное обеспечение',
    ],
  };