import '../../assets/styles/contacts/contacts.scss';

export function Map() {
  const mapStyle = {
    border: '0',
    borderRadius: '10px',
    allowFullScreen: '',
  };
  return (
    <div className="map_container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2001.332273423309!2d30.324492677149664!3d59.8934341748924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4696306a4cb7aa67%3A0xf239e3e91a935e4d!2z0JHQuNC30L3QtdGBLdCm0LXQvdGC0YAg0J_RgNC10LzRjNC10YAg0JvQuNCz0LA!5e0!3m2!1sru!2sfi!4v1706288638773!5m2!1sru!2sfi"
        width="100%"
        height="100%"
        style={mapStyle}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="map"
      ></iframe>
    </div>
  );
}
