import { MainMedicalEquipmentType } from '../../types/medicineTypes';

export const medicineCars: MainMedicalEquipmentType = {
  title: 'Преимущества:',
  item: [
    'Базовое транспортное средство подбирается с учетом пожеланий и потребностей Заказчика.',
    'Весь медицинский транспорт производится на заказ в строгом соответствии с техническим заданием.',
    'Автомобили скорой медицинской помощи и медицинские комплексы оснащаются оборудованием по ГОСТ.',
    'Гарантия на салоны 5 лет.',
    'Техническое обслуживание и ремонт специализированного автотранспорта.',
  ],
};
