import { JSX } from 'react/jsx-runtime';
import { Footer } from '../footer/Footer';
import { HeaderTop } from '../header/HeaderTop';
import { AboutStrategy } from './content/AboutStrategy';
import { AboutTechnicalSupport } from './content/AboutTechnicalSupport';
import { AboutTestimonies } from './content/AboutTestimonies';

export function AboutPage() {
  return (
    <>
      <HeaderTop />
      <div className="block_container about">
        <AboutStrategy />
        <AboutTechnicalSupport />
        <AboutTestimonies />
      </div>

      <Footer />
    </>
  );
}
