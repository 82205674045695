import FacilitySlider from './FacilityCarousel';
import { FacilityPhase } from './Facilityphase';
import { facilityData, facilityPhase } from './facilityData';

export function HealthCareFacility() {
  return (
    <section className="block_container">
      <div className="wrapper facility">
        <h3 className="block_title">
          Комплексное оснащение лечебно - профилактических учреждений
        </h3>
        <p className="divider_text block_text ">
          ООО «ИнжинирингКомплектСервис» предлагает различные решения по
          комплексному оснащению медицинским оборудованием любых отделений и
          кабинетов, в. т.ч. операционных залов, палат, реанимационных,
          диагностических, кардиологических, урологических, гинекологических
          отделений, отделений реабилитации и водолечения.
          <span className="divider_medicine"></span>
        </p>

        <p className="block_text">
          Наша компания готова предложить оптимизированные решения по
          комплексному оснащению медицинских объектов любой сложности и в
          соответствии с имеющимся бюджетом.
        </p>
        <FacilitySlider slides={facilityData} />
        <FacilityPhase data={facilityPhase} />
        <p className="block_text padding_top_50 padding_bottom_0">
          Комплексные решения для оснащения кабинетов, перечень оборудования
          каждого кабинета врача соответствует приказу Минздравсоцразвития РФ от
          01.12.2005 №753.
        </p>
      </div>
    </section>
  );
}
