import { Button, Container } from '@mui/material';

interface SuccessMessageProps {
  handleCloseModal: () => void;
}

export function SuccessMessage({ handleCloseModal }: SuccessMessageProps) {
  return (
    <Container className="modal_success">
      <Button
        className="success_button"
        style={{
          position: 'absolute',
          top: 30,
          right: 4,
          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        }}
        onClick={handleCloseModal}
      >
        <img
          src="assets/icons/icon_cross_mark.png"
          alt="cross-icon"
          style={{ width: 30 }}
        />
      </Button>

      <img src="assets/icons/icon_ok.png" className="success_icon" alt="ico" />
      <div className="success_text">
        <h2 className="title success_title">Спасибо за ваш запрос!</h2>
        <p className="success_text">Мы свяжемся с вами в ближайшее время.</p>
      </div>
    </Container>
  );
}
