import {
  ProductsImgType,
  ProductsLogoType,
  ProductsTextType,
} from './turnstilesTypes';

export const turnstilesText: ProductsTextType[] = [
  {
    text: 'Компания PERCo — ведущий российский производитель систем и оборудования безопасности. Входит в пятерку мировых производителей. Cовременные турникеты Perco включают скоростные проходы, тумбовые турникеты, турникеты-триподы, полноростовые роторные турникеты, калитки, позволяют организовать удобную интеграцию с дополнительным оборудованием – терминалами распознавания лиц, пирометрами, сканерами штрихкодов.',
  },
  {
    text: 'Фирма ОМА разрабатывает и совершенствует технику для систем контроля и управления доступом с 1999 года и в настоящее время готова предложить Вам широкий ассортимент недорогого, элегантного и высоконадежного оборудования. Современные турникеты ОМА, используемые в качестве исполнительных устройств в системах контроля и управления доступом – это технологичное, надежное и практичное решение. Турникеты ОМА и специальное оборудование, изготовленные из современных материалов, впишутся в самый престижный интерьер проходной.',
  },
  {
    text: 'Вся продукция Oxgard, выпускаемая с 2004 года собирается в Санкт-Петербурге на заводе Возрождение. Серия Praktika не является исключением. Каждая деталь этих турникетов изготовлена из материалов высочайшего качества с соблюдением строгих стандартов - это воплощение философии нашей компании.',
  },
];

export const turnstilesLogo: ProductsLogoType[] = [
  {
    logo: 'assets/products/perco.png',
  },
  {
    logo: 'assets/products/oma.png',
  },
  {
    logo: 'assets/products/oxgard.png',
  },
];

export const turnstilePerco: ProductsImgType[] = [
  {
    img: 'assets/products/1.png',
  },
  {
    img: 'assets/products/2.png',
  },
  {
    img: 'assets/products/3.png',
  },
  {
    img: 'assets/products/4.png',
  },
];

export const turnstileOma: ProductsImgType[] = [
  {
    img: 'assets/products/01.png',
  },
  {
    img: 'assets/products/02.png',
  },
  {
    img: 'assets/products/03.png',
  },
  {
    img: 'assets/products/04.png',
  },
];

export const turnstileOxgard: ProductsImgType[] = [
  {
    img: 'assets/products/001.png',
  },
  {
    img: 'assets/products/002.png',
  },
  {
    img: 'assets/products/003.png',
  },
  {
    img: 'assets/products/004.png',
  },
];
