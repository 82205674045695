import {
  AdvantagesTypes,
  PartnersTypes,
  PrombezCardTypes,
} from '../../prombezPage/prombezTypes';
import { MainMedicalEquipmentTypes } from '../../types/medicineTypes';

export const medicalItemsData: MainMedicalEquipmentTypes[] = [
  {
    title: 'Комплексное оснащение лечебных учреждений',
    link: '/medicine_equipment',
    item: [
      'Операционные',
      'Реанимационные',
      'Диагностика',
      'Кардиология, урология, гинекология',
      'Реабилитации',
    ],
  },

  {
    title: 'Медицинская одежда и СИЗ',
    link: '/medicine_clothes',
    item: [
      'Одежда и обувь',
      'Защитные комбинезоны, халаты,фартуки',
      'Бахилы, нарукавники, шапочки, шлемы',
      'Mаски, респираторы',
      'Перчатки, очки, экраны, щитки',
    ],
  },
];

export const medicalItemsData_2: MainMedicalEquipmentTypes[] = [
  {
    title: 'Современные машины скорой медицинской помощи',
    link: '/medicine_cars',
    item: [],
  },
  {
    title: 'Техническое обслуживание',
    link: '/medicine_technics',
    item: [],
  },
  {
    title: 'Медицинские IT-продукты',
    link: '/medicine_it_products',
    item: [],
  },
  {
    title: 'Медицинское оборудование',
    link: '/medicine_hardware',
    item: [
      'Ультра-звуковая диагностика',
      'Магнитно-резонансные томографы(МРТ)',
      'Компьютерные томографы (аппараты КТ)',
      'Рентгеновские аппараты',
      'Аппараты искусственной вентиляциилегких (ИВЛ)',
      'Мебель для медицинских учреждений',
    ],
  },
];

export const medicineAdvdntagesData: AdvantagesTypes[] = [
  {
    title: 'Индивидуальный подход',
    img: 'assets/medicine/main_medicine/Component 6.png',
    description:
      'Постоянное развитие предложения и индивидуальные отраслевые решения в соответствии с требованиями Заказчика',
  },
  {
    title: 'Надежность',
    img: 'assets/medicine/main_medicine/Component 7.png',
    description:
      'Устойчивые деловые связи \n с компаниями-производителями \n(оборудование от 500+ мировых и российских производителей)',
  },
  {
    title: 'Российское ПО',
    img: 'assets/medicine/main_medicine/Component 8.png',
    description:
      'Эффективные решения \n от российских вендоров в сфере программного обеспечения и ИТ-оборудования в рамках импортозамещения',
  },
  {
    title: 'Решения под ключ',
    img: 'assets/medicine/main_medicine/Component 11.png',
    description: 'Построение комплексных решений для проектов под ключ',
  },
  {
    title: 'Цена',
    img: 'assets/medicine/main_medicine/Component 9.png',
    description:
      'Гибкая ценовая политика за счет использования современных бизнес-моделей',
  },
  {
    title: 'Ассортимент',
    img: 'assets/medicine/main_medicine/Component 10.png',
    description:
      'Наличие широкого ассортимента продукции на складах РФ и развитая система логистики обеспечивают минимальные сроки поставки и интеграции',
  },
];

export const dataSliderMainMedicine: PrombezCardTypes[] = [
  {
    title: 'ПБ ГБУЗ «Детская ГП No68»',
    text: 'Поставка средств индивидуальной защиты для нужд СПБ ГБУЗ «Детская ГП No68»',
    img: 'assets/medicine/main_medicine/business-team-meeting-strategy-marketing-cafe-concept 3.png',
  },
  {
    title:
      'ФГБОУ ВО "Северный Государственный медицинский университет" г. Архангельск',
    text: 'Поставка медицинского оборудования для учебно-экспериментальной операционной ФГБОУ ВО "Северный Государственный медицинский университет" г. Архангельск',
    img: 'assets/medicine/main_medicine/business-team-meeting-strategy-marketing-cafe-concept 8.png',
  },
  {
    title: 'Медицинские учреждения Архангельской области',
    text: 'Поставка и ввод в эксплуатацию комплексов для многосуточного мониторирования ЭКГ (по Холтеру) для нужд медицинских учреждений Архангельской области',
    img: 'assets/medicine/main_medicine/Component 12.png',
  },
  {
    title: 'ГКУ РК «Крымздрав»',
    text: 'Поставка медицинских изделий для нужд ГКУ РК «Крымздрав»',
    img: 'assets/medicine/main_medicine/business-team-meeting-strategy-marketing-cafe-concept 5.png',
  },
  {
    title: 'ФГАУ «УИСП» Минобороны России',
    text: 'Поставка аппаратов ИВЛ для нужд ФГАУ «УИСП» Минобороны России',
    img: 'assets/medicine/main_medicine/business-team-meeting-strategy-marketing-cafe-concept 4.png',
  },
];

export const PartnersMedicineData: PartnersTypes[] = [
  {
    img: 'assets/medicine/main_medicine/Rectangle 4003.png',
  },
  {
    img: 'assets/medicine/main_medicine/Rectangle 4002.png',
  },
  {
    img: 'assets/medicine/main_medicine/Rectangle 4001.png',
  },
  {
    img: 'assets/medicine/main_medicine/Rectangle 4000.png',
  },
  {
    img: 'assets/medicine/main_medicine/Rectangle 4004.png',
  },
];
