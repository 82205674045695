import React, { useRef, useState } from 'react';

import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import Card from './Card';
import { ActivityTypes } from './activityTypes';

import '../../../../assets/styles/mainPage/sliderStyles.scss';

SwiperCore.use([Navigation, Pagination]);

interface SliderProps {
  slides: ActivityTypes[];
}

const Slider: React.FC<SliderProps> = ({ slides }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const swiperRef = useRef(null);
  const [isPrevButtonActive, setPrevButtonActive] = useState(false);
  const [isNextButtonActive, setNextButtonActive] = useState(true);

  const handleSlideChange = (swiper: SwiperCore) => {
    setPrevButtonActive(!swiper.isBeginning);
    setNextButtonActive(!swiper.isEnd);
  };

  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      disableOnInteraction: false,
    },
    loop: true,
    speed: 1500,
    navigation: {
      nextEl: '.btn_next',
      prevEl: '.btn_prev',
    },
    on: {
      slideChange: () =>
        handleSlideChange(swiperRef.current || ({} as SwiperCore)),
    },
  };

  const mainSwiperId = 'mainId';

  return (
    <div className="swiper-container card-container">
      <Swiper id={mainSwiperId} {...params} onSwiper={setSwiper}>
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="text-container">
            <Card
              title={slide.title}
              text={slide.text}
              img={slide.img}
              year={slide.year}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="arrows_slider">
        <button className="btn_prev"></button>
        <button className="btn_next"></button>
      </div>
      <div className="swiper-pagination pagination-container"></div>
    </div>
  );
};

export default Slider;
