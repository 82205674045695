import { MainMedicalEquipmentType } from '../../types/medicineTypes';

export const technicsAdvantages: MainMedicalEquipmentType = {
  title: 'Преимущества:',
  item: [
    'Полное восстановление рентгеновских трубок, а также диагностика и устранение любых других неисправностей всех моделей компьютерных томографов (КТ) производства Canon (Toshiba) и General Electric, а также КТ Siemens Emotion;',
  ],
};

export const technicsDiagnosis: MainMedicalEquipmentType = {
  title:
    'Диагностика и устранение любых неисправностей следующих медицинских изделий производства Canon (Toshiba), General Electric, Siemens:',
  item: [
    '1. Ангиографические аппараты',
    '2. Рентгеновские аппараты',
    '3. Ультразвуковые аппараты',
    '4. Маммографы',
  ],
};
export const technicsDevelopment: MainMedicalEquipmentType = {
  title: 'Разработка и доработка:',
  item: [
    '1. Разработка инновационных электронных устройств для оборудования',
    '2. Оптимизация работы оборудования с учетом требований',
    '3. Расширение функциональных возможностей оборудования',
    '4. Модификация существующих электронных устройств для повышения их эффективности и совершенствования',
  ],
};
