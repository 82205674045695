import { HeaderTop } from '../header/HeaderTop';

import '../../assets/styles/404Page.scss';

export function Page404() {
  return (
    <>
      <section className="page_not_found">
        <HeaderTop />
        <p className="title not_found_title">
          В настоящий момент страница находится в разработке
        </p>
      </section>
    </>
  );
}
