import React, { useEffect } from 'react';
import { AlcoBenefitsTypes } from '../alcoTypes';
import '../../../assets/styles/prombez/innovation.scss';
import '../../../assets/styles/alcoframe/alcoframe.scss';
import { textColorChanger } from '../../utils/textColorChanger';

interface DataProps {
  data: AlcoBenefitsTypes[];
}

export const AlcoBenefits: React.FC<DataProps> = ({ data }) => {
  useEffect(() => {
    const titleElement = document.querySelector(
      '.alco_benefits_title'
    ) as HTMLTitleElement;
    textColorChanger(titleElement);
  }, []);
  return (
    <section className="wrapper alco_benefits">
      <h2 className="block_title alco_benefits_title">
        КЛЮЧЕВЫЕ ПРЕИМУЩЕСТВА АЛКОРАМКИ
      </h2>
      <div className="alco_benefits_list">
        {data.map((list, index) => (
          <div key={index} className="alco_benefit">
            <div
              className="img_container"
              style={{
                width: index === 0 || index === 4 ? '40px' : '50px',
              }}
            >
              <img src={list.ico} alt="ico" className="alco_benefits_ico" />
            </div>
            <h3
              className="benefits_title"
              style={{
                paddingBottom: index === 8 || index === 11 ? '38px' : '17px',
              }}
            >
              {list.title}
            </h3>
            <p className="benefits_text">{list.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
