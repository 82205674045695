import { Footer } from '../../footer/Footer';
import { HeaderTop } from '../../header/HeaderTop';
import { GetCalculation } from '../../prombezPage/content/getCalculation/GetCalculation';
import { FacilityPhase } from '../healthCareFacility/Facilityphase';
import { HardwareItemsList } from './HardwareItemsList';
import {
  ALV,
  CT,
  MRI,
  furniture,
  hardwareCategories,
  ultraSound,
  xRay,
} from './hardwareData';

import '../../../assets/styles/medicine/hardware/hardwareStyles.scss';

export function MedicineHardwarePage() {
  return (
    <>
      <HeaderTop />
      <section className="block_container">
        <div className="wrapper hardware">
          <h3 className="block_title ">Медицинское оборудование</h3>
          <p className="divider_text block_text padding_bottom_0">
            ООО «ИнжинирингКомплектСервис» реализует высокотехнологичную
            медицинскую технику, медицинские инструменты, расходные материалы по
            различным направлениям: диагностика, малоинвазивная хирургия,
            стерилизация и дезинфекция, робот-ассистированная хирургия,
            ультразвуковая диагностика, рентгенология и томография, оборудование
            для терапии и пр.
            <span className="divider_medicine"></span>
          </p>
          <HardwareItemsList data={ultraSound} />
          <HardwareItemsList data={MRI} />
          <HardwareItemsList data={CT} />
          <HardwareItemsList data={xRay} />
        </div>
        <GetCalculation />
        <div className="wrapper hardware">
          <HardwareItemsList data={ALV} />
          <HardwareItemsList data={furniture} />
          <FacilityPhase data={hardwareCategories} />
          <p className=" plasmoran_text ">
            Наряду с поставками современных диагностических систем наша компания
            обеспечивает их внедрение, сопровождение, сервисное обслуживание,
            обучение врачей и среднего медперсонала методам эффективной и
            безопасной работы на предлагаемом оборудовании.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}
