export function textColorChanger(element: HTMLTitleElement | null) {
  if (element) {
    const text = element.textContent || '';
    const words = text.trim().split(' ');
    const lastWord = words[words.length - 1];
    const restOfText = text.slice(0, text.length - lastWord.length);

    element.innerHTML = `${restOfText}<span style="color: #E10B0B;">${lastWord}</span>`;
  }
}
