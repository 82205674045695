import Slider from './Slider';

import { dataActivity } from './dataActivity';

import '../../../../assets/styles/mainPage/activity.scss';
import '../../../../assets/styles/commonStyles.scss';

export function Activity() {
  return (
    <section className="block_container activity_container">
      <div className="wrapper main_wrapper">
        <h2 className="block_title left_padding__title activity_main_title">
          Общественная деятельность
        </h2>
        <Slider slides={dataActivity} />
      </div>
    </section>
  );
}
