import { Footer } from '../../footer/Footer';
import { HeaderTop } from '../../header/HeaderTop';
import { FacilityPhase } from '../healthCareFacility/Facilityphase';
import { medicineCars } from './carsData';

import '../../../assets/styles/medicine/cars/medicineCars.scss';

export function MedicineCarsPage() {
  return (
    <>
      <HeaderTop />
      <section className="block_container ">
        <div className="wrapper">
          <h3 className="block_title">
            Современные машины скорой медицинской помощи
          </h3>
          <p className="divider_text block_text">
            Автомобили скорой помощи собираются на базе УАЗ Профи. Предназначены
            для проведения лечебных мероприятий скорой медицинской помощи силами
            врачебной (фельдшерской)бригады, транспортировки и мониторинга
            состояния пациентов на догоспитальном этапе.
            <span className="divider_medicine"></span>
          </p>
          <div className="imgs_container">
            <img
              src="assets/medicine/cars/Rectangle 4446.png"
              alt="car"
              className="medicine_car_img"
            />
            <img
              src="assets/medicine/cars/Rectangle 4447.png"
              alt="car"
              className="medicine_car_img"
            />
          </div>
          <p className="block_text padding_bottom_0">
            Для перевозки больного, медицинского персонала и установки
            оборудования используется пластиковый интегральный кузов с силовым
            каркасом из стальных труб.
          </p>
          <FacilityPhase data={medicineCars} />

          <img
            src="assets/medicine/cars/Rectangle 4448.png"
            alt="car"
            style={{ marginTop: '50px', marginBottom: '47px' }}
            className="car"
          />
        </div>
      </section>

      <Footer />
    </>
  );
}
