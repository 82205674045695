import { NavLink } from 'react-router-dom';

import SafetyList from './SafetyList';
import { controlData, itData, videoData } from './dataSafety';

import '../../../../assets/styles/prombez/safety.scss';

export function Safety() {
  return (
    <section className="block_container safety">
      <div className="wrapper">
        <h2 className="block_title ">
          Наши решения в сфере промышленной и гражданской безопасности
        </h2>
      </div>
      <div className="safety_wrapper">
        <div className="control common_styles">
          <SafetyList data={controlData} />
          <NavLink className="footer_link" to="/turnstiles">
            <button className="button safety_button_control red_btn_hover ">
              Подробнее...
            </button>
          </NavLink>
        </div>
        <div className="video_it">
          <div className="video common_styles">
            <SafetyList data={videoData} />
            <button className="button button_video red_btn_hover">
              <NavLink className="footer_link button_link" to="/video">
                Подробнее...
              </NavLink>
            </button>
          </div>
          <div className="it common_styles">
            <SafetyList data={itData} />
            <button className="button button_it red_btn_hover">
              <NavLink className="footer_link button_link" to="/it">
                Подробнее...
              </NavLink>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
