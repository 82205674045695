import '../../assets/styles/contacts/contacts.scss';

export interface ContactsTypes {
  img: string;
  ico: string;
  title: string;
  text: string;
  info: string;
}

export function Contact({ data }: { data: ContactsTypes[] }) {
  return (
    <>
      <div className="contacts">
        {data.map((data, index) => (
          <div className="contact_card" key={index}>
            <img src={data.img} alt="img" className="contacts_image" />
            <div className="icon_block">
              <img src={data.ico} alt="ico" className="about_ico" />
              <h5 className="contacts_about_title">{data.title}</h5>
            </div>
            <p className="contact_text">{data.text}</p>
            <p className="contacts_info">{data.info}</p>
          </div>
        ))}
      </div>
    </>
  );
}
