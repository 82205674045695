import { MainMedicalItems } from './MainMedicalItems';
import { medicalItemsData, medicalItemsData_2 } from './MedicalItemsData';

export function MainMedicalEquipment() {
  return (
    <>
      <section className="wrapper">
        <h3 className="block_title">Медицинское оборудование и СИЗ</h3>
        <div className="equipment_blocks">
          <MainMedicalItems data={medicalItemsData} />
          <MainMedicalItems data={medicalItemsData_2} />
        </div>
      </section>
    </>
  );
}
