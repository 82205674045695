import React from 'react';

import { SafetyTypes } from '../../../prombezPage/prombezTypes';

import '../../../../assets/styles/prombez/safety.scss';

interface DataProps {
  data: SafetyTypes[];
}

const SafetyList: React.FC<DataProps> = ({ data }) => {
  return (
    <div className="">
      {data.map((safety, index) => (
        <div className="safety_block" key={index}>
          <h3 className="list_title">{safety.title}</h3>
          <ul>
            {safety.item.map((item, itemIndex) => (
              <li key={itemIndex} className="item">
                {item}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default SafetyList;
