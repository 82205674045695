import '../../../assets/styles/about/aboutStyles.scss';

export function AboutTechnicalSupport() {
  return (
    <div className="wrapper">
      <h2 className="block_title ">Техническое сопровождение</h2>
      <div className="about_support">
        <p className="about_support_text block_text">
          Технические специалисты компании проследят за качественным проведением
          монтажа оборудования в соответствии со всеми требованиям и
          рекомендациям производителя, осуществят пуско-наладочные работы и ввод
          в эксплуатацию.
        </p>
        <img
          src="assets/about_page/unsplash_M_NvKwSOkug.png"
          alt="img"
          className="about_support_img"
        />
      </div>

      <div className="about_support">
        <img
          src="assets/about_page/unsplash_W9S1U2O6juY.png"
          alt="img"
          className="about_support_img"
        />
        <p className="about_support_text block_text">
          На все поставляемое оборудование предоставляется гарантия в
          соответствии с условиями производителей, а так же возможность
          последующего сервисного обслуживания на площадке Заказчика в
          послегарантийный период.
        </p>
      </div>
    </div>
  );
}
