import React, { useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { FacilityType } from '../../types/medicineTypes';

import '../../../assets/styles/medicine/facility/facilityStyles.scss';
import '../../../assets/styles/mainPage/sliderStyles.scss';

SwiperCore.use([Navigation, Pagination]);

interface SliderProps {
  slides: FacilityType[];
}

const ClothesSlider: React.FC<SliderProps> = ({ slides }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      disableOnInteraction: false,
    },
    loop: true,
    slidesPerView: 4,
    speed: 1500,

    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
        // spaceBetween: 5,
      },
      1024: {
        slidesPerView: 4,
        // spaceBetween: 60,
      },
    },
  };

  const mainSwiperId = 'mainId';

  return (
    <div className="swiper-container card-container facility_container">
      <Swiper id={mainSwiperId} {...params} onSwiper={setSwiper}>
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="text-container">
            <img
              src={slide.img}
              alt="img"
              className="facility_img clothes_img"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-pagination pagination-container"></div>
    </div>
  );
};

export default ClothesSlider;
